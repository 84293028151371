import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/src/State/store";
import { Button } from "../../Components/ui/button";
// import { login, increment } from "../../State/slices/AuthenticationSlice";

const Audience = () => {
    // const dispatch = useDispatch();




    // const email = useSelector((state: RootState) => state.authentication.userEmail);


    // const count = useSelector((state: RootState)=> state.authentication.count);

    const [inputEmail, setInputEmail] = useState("");
    const  [number, setNumber] = useState(0);


    // const handleButtonClick = () => {
    //     // Dispatch the action to set email in Redux
    //     dispatch(login(inputEmail));
    // };

    // const handlecount = () =>{
    //     dispatch(increment(number));
    // }



    return (
        <>
            {/* <h1>This Page is currently under development</h1> */}
            {/* <span>
                <input
                    type="text"
                    value={inputEmail}
                    onChange={(e) => setInputEmail(e.target.value)}
                    placeholder="Enter email"
                />
                <button onClick={handleButtonClick}>Set Email</button>
            </span>
            <p>Current Email in Redux: {email}</p>
            <input
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(parseInt(e.target.value))}
                    placeholder="Enter a number"
                />
            <p>count: {count}</p>
            <button onClick={handlecount}>increment</button> */}


                {/* <div className="flex flex-col justfy-center">

                    <div>
                        <p className="text-[24px] font-bold">Here you will see all your message templates</p>
                    </div>

                    <div>
                        <p className="text-[14px] text-[#64748B]">Click the button below to create your first template.</p>
                    </div>
                    <div>
                        <Button className="w-[140px] h-[36px] mt-0">Create Template</Button>
                    </div>


                </div> */}







        </>
    );
};

export default Audience;
