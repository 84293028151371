import React, { FC, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { CircleCheck, Plus, User, Settings, LogOut } from "lucide-react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { CustomWorkspaceControl } from "../../Pages/Workspace";
import { useDispatch } from "react-redux";
import { setAddWorkspaceFromDropdown } from "../../State/slices/AdvertiserAccountSlice";
import { setworkspace } from "../../State/slices/AuthenticationSlice";
import config from "../../config.json";
import { useSelector } from "react-redux";
import { RootState } from "../../State/store";
import axios from "axios";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../Components/ui/tooltip";
// Define props interfaces
interface DropdownMenuDemoProps {
  profileImage: string;
  profileName: string;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Workspace {
  workspace_id: number;
  workspace_name: string;
  // Add other properties as needed
}

interface WorkspaceDialogProps {
  open: boolean;
  handleClose: () => void;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

// AddWorkspace component
export const AddWorkspace: FC<WorkspaceDialogProps> = ({ open, handleClose, setAuthenticated }) => {
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        document.body.style.pointerEvents = "";
      }, 500);
    }
  }, [open]);
  const [next, setNext] = useState(true);
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create a new workspace</DialogTitle>
          <DialogDescription>
            Create a new workspace to manage your campaigns and members.
          </DialogDescription>
        </DialogHeader>
        {/* Content, e.g., form fields, buttons */}
        <CustomWorkspaceControl
          setNext={setNext}
          setAuthenticated={setAuthenticated}
        />
      </DialogContent>
    </Dialog>
  );
};

// DropdownMenuDemo component
export function DropdownMenuDemo({
  profileImage,
  profileName,
  setAuthenticated,
}: DropdownMenuDemoProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [workspaceList, setWorkspaceList] = useState<Workspace[]>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  // const [apiUrl, setApiUrl] = useState('');
  const email = useSelector((state:RootState)=>state.authentication.userEmail);
  const apiUrl = useSelector((state:RootState)=>state.authentication.apiURL);
  const mdSize = "text-[14px]";
  const closeAddWorkspaceDialog = useSelector((state:RootState)=>state.advertiserAccount.closeAddWorkspaceDialog);
  // const seturl=async()=>{
  //   await setApiUrlAdvAcc(config.ApiUrlAdvAcc);
  // }
  useEffect(() => {
    // seturl();
    try{
      const GetMultipleWorkspacesByEmail = async () => {
        const response = await axios.get(
          `${apiUrl}/GetMultipleWorkspacesByEmail?EmailId=`+email
        );
        if (response.data.status == "Success") {
          console.log(response.data.workspaceList);
          setWorkspaceList(response.data.workspaceList);
        }
      };
      GetMultipleWorkspacesByEmail();
    }
    catch(error){
      console.error("Network error: ",error);
    }

  }, []);

  useEffect(()=>{
    if(closeAddWorkspaceDialog){
      handleClose();
    }
  },[closeAddWorkspaceDialog])


  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <span className="w-[calc(100%-25px)] text-left flex items-center mr-3 text-gray-500 hover:text-black rounded-lg px-3 py-2 cursor-pointer">
            <Avatar>
              <AvatarImage src={profileImage} alt="Profile" className="h-[25px] w-[25px] rounded-full mt-2" />
              <AvatarFallback>TA</AvatarFallback>
            </Avatar>
            <span className={"text-black bg-transparent sticky ml-[-6px] "+mdSize} style={{fontWeight:'bold'}}>
              {profileName.length > 9 ? `${profileName.slice(0, 9)}...` : profileName}
            </span>
            <CaretSortIcon className="ml-4" style={{color:'#020617'}} />
          </span>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuGroup>
            
          {workspaceList && workspaceList.map((data) => (



              <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
              <DropdownMenuItem onClick={() =>{
                dispatch(setworkspace(data.workspace_name))
                navigate("/navbar/dashboard", {
                state: { path: data.workspace_name },
              })}
            }>
                <Avatar className="mr-2 h-4 w-4">
                  <AvatarImage
                    src={profileImage}
                    alt="Profile"
                    className="h-5 w-5 rounded-full"
                  />
                </Avatar>
                <span
                  key={data.workspace_id}

                  
                >
                  {(data.workspace_name).length>=17?`${(data.workspace_name).slice(0, 17)}...`:data.workspace_name}
                </span>
                {data.workspace_name===profileName?(<>
                <div className=" flex flex-grow justify-end">
                  <CircleCheck color="green" size={15} className="ml-2" />
                </div>
                </>):(
                  <></>
                )}
              </DropdownMenuItem>
              </TooltipTrigger>
                  <TooltipContent>
                    <p>{data.workspace_name}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}

            <DropdownMenuItem onClick={() => {
                  dispatch(setAddWorkspaceFromDropdown(true));
                  handleOpen();
                }}>
              <Plus className="mr-2 h-4 w-4" />
              <span>
                Add New Workspace
              </span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <User className="mr-2 h-4 w-4" />
              <span onClick={() => navigate("/settings/Profile", { state: { path: profileName } })}>
                Profile settings
              </span>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Settings className="mr-2 h-4 w-4" />
              <span onClick={() => navigate("/settings/Workspace_Settings", { state: { path: profileName } })}>
                Workspace Settings
              </span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <LogOut className="mr-2 h-4 w-4" />
            <span onClick={() => { setAuthenticated(false); navigate("/"); }}>Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Render AddWorkspace dialog outside the DropdownMenu for better visibility control */}
      <AddWorkspace open={open} handleClose={handleClose} setAuthenticated={setAuthenticated} />
    </>
  );
}
