import React, { useState, useEffect,ChangeEvent } from 'react'
import { Button } from "../../Components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../Components/ui/select';
import { Input } from '../../Components/ui/input';
import { Label } from "../../Components/ui/label";
import { Textarea } from '../../Components/ui/textarea';
import { FaTrash, FaTimes, FaFilePdf, FaFileWord } from "react-icons/fa";
import { FaPlus, FaCaretDown } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import {Container, Box, Typography, TextField, IconButton } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Value } from '@radix-ui/react-select';
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import config from  '../../config.json';
import { ChevronDownIcon, PlusIcon } from '@radix-ui/react-icons';
import { useDispatch } from "react-redux";
import { setCreateBreadCrumb } from "../../State/slices/AdvertiserAccountSlice";


interface BoxItem {
  action: string;
}


// Define the Country type
interface Language {
  language_id: number;
  language_name: string;
}

interface TemplatePlatform {
  platform_id: number;
  platform_name: string;
}

interface GetButtonTypeList {
  button_id: number;
  button_type: string;
}

interface Template {
  template_id: number;
  template_name: string;
}

interface Content {
  content_id: number;
  content_name: string;
}

// interface GetButtonType {
//   button_id: number;
//   button_type: string;
// }

interface Channel {
  channel_id: number;
  channel_name: string;
}

const textAreaIcon=()=>{
    return(
      <div className="relative w-8 h-8 flex justify-center items-center" >
      <div
        className="absolute rounded w-[3px] h-8 transform rotate-45" style={{ borderLeft: '3px solid #64748B' }}
      />
      <div
        className="absolute w-[3px] h-4 rounded transform rotate-45 translate-x-2 translate-y-2" style={{ borderLeft: '3px solid #64748B' }}
      />
    </div>
    )
  }

const CreateTemplate: React.FC = () => {

const handleDropdownToggle = () => {
  setShowDropdown(!showDropdown);
};

const handleActionSelect = (action: string) => {
  setSelectedAction(action);
  setShowDropdown(false);
};
  const navigate = useNavigate();
  const location = useLocation();
  const templateId = location.state?.templateId || "";

  const [platform , setPlatform] = useState('');
  const [templatePlatform, setTemplatePlatform] = useState<TemplatePlatform[]>([]); 
  const [languageList, setLanguageList] = useState<Language[]>([]); 
  const [language,setLanguage] = useState('');
  const [Content,setContent] = useState('');
  const [ContentList,setContentList] = useState<Content[]>([]);
  const [buttonType, setButtonType] = useState('');
  const [buttonList, setButtonList] = useState<GetButtonTypeList[]>([]);
  const [templateList, setTemplateList] = useState<Template[]>([]);
  const [templateName, setTemplateName] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [textInput, setTextInput] = useState<string>('');
  const [headerType, setHeaderType] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [bodyText, setBodyText] = useState<string>("");
  const [FooterText, setFooterText] = useState<string>("");
  const [buttonT , setButtonT] = useState<number>(0);
  const [templatePreview, setTemplatePreview] = useState<string>("");
  const [buttonData, setButtonData] = useState<{ buttonText: string; websiteUrl: string }>({
    buttonText: "",
    websiteUrl: "",
  });
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [buttonTypeDropdown, setButtonTypeDropdown] = useState<string>("static");
  const [buttonText, setButtonText] = useState<string>("");
  const [websiteUrl, setWebsiteUrl] = useState<string>("");
  const [boxes, setBoxes] = useState<BoxItem[]>([]);
  const [channelList, setChannelList] = useState<Channel[]>([]); // State for the channel list
  const [channel, setChannel] = useState('');
  const [templateType , setTemplateType] = useState('');

  const [updateplatform , setUpdatePlatform] = useState('');
  const [updateLanguage,setUpdateLanguage] = useState('');
  const [updateButtonType,setUpdateButtonType] = useState('');
  const [updateContentList,setUpdatedContentList] = useState('');
  const [updateTemplateList,setUpdatedTemplateList] = useState('');
  const [updateChannel,setUpdateChannel]=useState('');
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState('');

  const [PlatformNameError, setPlatformNameError] = useState<string | null>(null);
  const [templateError, setTemplateError] = useState<string | null>(null);
  const [LanguageError, setLanguageError] = useState<string | null>(null);
  const [channelError, setChannelError] = useState<string | null>(null);
  const [BodyError, setBodyError] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfig = async () => {
        try {
            const response = await fetch('/config.json');
            const config = await response.json();
            setApiUrlAdvAcc(config.ApiUrlAdvAcc);  
        } catch (error) {
            console.error('Error loading config:', error);
        }
    };

    fetchConfig();
}, []);
  
  const getLanguageList = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetLanguageList`);
   
      // Assuming the response data contains a 'CountryList' field as discussed earlier
      if (response.data && response.data.languageList) {
        setLanguageList(response.data.languageList);
        console.log("Language List : " , response.data.languageList)
      } else {
        console.log("No Language list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching Language list:", error);
    } finally {
      setLoading(false);
    }
  };



  const getTemplatePlatform = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetTemplatePlatform`);
      if (response.data && response.data.templatePlatform) {
        setTemplatePlatform(response.data.templatePlatform);
        console.log("Template Platform : " , response.data.templatePlatform)
      } else {
        console.log("No Template Platdform available in response.");
      }
    } catch (error) {
        console.error("Error fetching Template Platform:", error);
    } finally {
      setLoading(false);
    }
  };


  const getbuttonType = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetButtonType`);
      if (response.data && response.data.buttonType) {
        setButtonList(response.data.buttonType);
        console.log("ButtonType List : " , response.data.buttonType)
      } else {
        console.log("No Button Type available in response.");
      }
    } catch (error) {
      console.error("Error fetching Button Type:", error);
    } finally {
      setLoading(false);
    }
  };


 const getContentType = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetContentList`);
   
      if (response.data && response.data.contentList) {
        setContentList(response.data.contentList);
        console.log("ContentType : " , response.data.contentList)
      } else {
        console.log("No Content Type available in response.");
      }
    } catch (error) {

      console.error("Error fetching Content Type:", error);
    } finally {
      setLoading(false);
    }
  };


  const getTemplateList = async () => {
    setLoading(true);
 

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetTemplateList`);
      
      if (response.data && response.data.templateList) {
        setTemplateList(response.data.templateList);
        console.log("Template List : " , response.data.templateList)
      } else {
        console.log("No template list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching template list:", error);
    } finally {
      setLoading(false);
    }
  };

  const getChannelList = async () => {
    setLoading(true);
 

    try {
    const response = await axios.get(`${apiUrlAdvAcc}/GetChannelList`);
     
     
      if (response.data && response.data.channelList) {
        setChannelList(response.data.channelList);
        console.log("Channel List : " , response.data.channelList)
      } else {
        console.log("No channel list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching country list:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadTemplateList = async (id: any) => {
    setLoading(true);
    console.log("templateId API::" , id)
  
    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetTemplateDetailsById?TemplateId=`+id);
      console.log ("response.data.templateDetails:"+response.data.templateDetails)
      if (response.data && response.data.templateDetails) {
        const templateDetailslocal = response.data.templateDetails[0];
       
        console.log("Template List By Id:", templateDetailslocal);
        console.log("Ttemplate_type:", templateDetailslocal.template_type);
  
        setUpdatePlatform(templateDetailslocal.platform_name);
        setTemplateName(templateDetailslocal.template_name); // Set other fields as needed
        setUpdateChannel(templateDetailslocal.template_type); 
        setUpdateLanguage(templateDetailslocal.language_name);
        setUpdatedContentList(templateDetailslocal.template_header);
        setBodyText(templateDetailslocal.template_body);
        setFooterText(templateDetailslocal.template_footer);
        setUpdateButtonType(templateDetailslocal.button_type);
        setButtonText(templateDetailslocal.button_text);
        setHeaderType(templateDetailslocal.template_header);
        setTemplatePreview(templateDetailslocal.template_body);

        handleOptionChange(templateDetailslocal.template_header);

      } else {
        console.log("No Templates details available in response.");
      }
    } catch (error) {
      console.error("Error fetching template details:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
        try {
            if (apiUrlAdvAcc) {
                await getTemplatePlatform(); // Ensure the channel list is loaded first
                if (templateId) {
                    await loadTemplateList(templateId); // Load templates based on templateId
                }
                await Promise.all([
                    getLanguageList(),
                    getbuttonType(),
                    getContentType(), 
                    getChannelList(),
                ]);
                // updateTemplatePreview(); // Call to update the preview after loading data
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, [apiUrlAdvAcc, templateId]); // Depend on apiUrlAdvAcc and templateId



const validatePlatformName = () => {
  if (!platform && !updateplatform) {
    setPlatformNameError("Platform Name is required");
    return false;
  }

  setPlatformNameError(null);
  return true;
};

const validateTemplateName = () => {
  if (!templateName.trim()) {
    // Ensure either selected template or updateTemplate exists
    setTemplateError("Template Name is Required");
    return false;
  }
  setTemplateError(null);
  return true;
};

const validateLanguage = () => {
  if (!language && !updateLanguage) {
    // Ensure either selected channel or updateChannel exists
    setLanguageError("Please select a Language");
    return false;
  }
  setLanguageError(null);
  return true;
};



const validateChannel = () => {
  if (!channel && !updateChannel) {
    // Ensure either selected channel or updateChannel exists
    setChannelError("Please select a channel");
    return false;
  }
  setChannelError(null);
  return true;
};

const validateBody = () => {
  if (!bodyText.trim()) {
    setBodyError("");
    return false;
  }
  setBodyError(null);
  return true;
};

 
  const handleEdit = async () => {

    console.log("buttonType:" , buttonType);

    const selectedplatform = templatePlatform.find(findPlatform => findPlatform.platform_name === updateplatform);
    const updatePlatformId = selectedplatform ? selectedplatform.platform_id : null;

    const selectedLanguage = languageList.find(findLanguage => findLanguage.language_name === updateLanguage);
    const updateLanguageId = selectedLanguage ? selectedLanguage.language_id : null;

    const selectedButtonType = buttonList.find(findButtonType => findButtonType.button_id === parseInt(updateButtonType));
    const updateButtonId = selectedButtonType ? selectedButtonType.button_id : null;

    const selectedContentType = ContentList.find(findContentType => findContentType.content_name  === updateContentList);
    const updateContentId = selectedContentType ? selectedContentType.content_id : null;
   
    const selectedTemplateType = templateList.find(findTemplateType => findTemplateType.template_name  === updateTemplateList);
    const updateTemplateId = selectedTemplateType ? selectedTemplateType.template_id : null;

    const selectedChannel = channelList.find(findchannel => findchannel.channel_name=== updateChannel);
    const updateChannelId = selectedChannel ? selectedChannel.channel_id : null;

debugger
    console.log("platform:",platform,"updatePlatformId:", updatePlatformId)
    console.log("language:" , language , "updateLanguageId::" , updateLanguageId)
    console.log("buttonType:" , buttonType , "updateButtonId::" , updateButtonId )
    console.log("TemplatType:" , templateType , "updateTemplateId::" , updateTemplateId )
debugger

if (
  !validatePlatformName() ||
  !validateTemplateName() ||
  !validateLanguage() ||
  !validateChannel() ||
  !validateBody()
) {
  // If validation fails, do not submit
  return;
}

    try{
      const data={

        "TemplateId":templateId,
        "PlatformName": platform==""?updatePlatformId:platform,
        "TemplateType": templateType==""?updateChannelId:templateType,
        "TemplateName": templateName,
        "TemplateLanguage": language==""?updateLanguageId:language,
        "TemplateHeader": headerType,
        "TemplateBody": bodyText,
        "TemplateFooter": FooterText,
        "Components": "String",
        "ButtonType":  buttonType==""?updateButtonId:buttonType,
        "ButtonText": buttonText,
        "UpdateBy":"1",
        "UpdatedDate":"2024-10-05T14:35:00",
        "Status":"Pending"
      }
      debugger
      console.log("loadingdata:" ,data);
      console.log(data);
      debugger
      
      const response = await axios.put(`${apiUrlAdvAcc}/UpdateTemplate?TemplateId=${templateId}`, data);
      
      if (response.data.status === 'Success') {
          toast.success("The Template details were updated successfully",{
            autoClose:1000,
            onClose:() => {
              navigate('/navbar/TemplateList');
            }
          });
       
           
        
      } else {
          console.error('Upload failed:', response.data.Status_Description);
          toast.error("An error occurred while updating the Template details");
          setTimeout(() =>{/* wait for 1 second */},1000)
      }
    } catch (e) {
      console.error("Error in submitting form", e);
    }
  }

const addVariable = () => {
    const newPlaceholder = `{{${boxes.length + 1}}}`;
    setBodyText(bodyText + ` ${newPlaceholder}`);
    setBoxes([...boxes, { action: "" }]);
  };

const updateButtonAction = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newBoxes = boxes.slice();
    newBoxes[index].action = event.target.value;
    setBoxes(newBoxes);
    updateTemplatePreview();
  };

  const onTextboxDelete = (index: number) => {
    // const newBoxes = boxes.filter((_, i) => i !== index);
    // let updatedBodyText = bodyText;
    // const placeholderToRemove = `{{${index + 1}}}`;
    // updatedBodyText = updatedBodyText.replace(placeholderToRemove, "").trim();

    // for (let i = index; i < newBoxes.length; i++) {
    //   const oldPlaceholder = `{{${i + 2}}}`;
    //   const newPlaceholder = `{{${i + 1}}}`;
    //   updatedBodyText = updatedBodyText.replace(new RegExp(oldPlaceholder, "g"), newPlaceholder);
    // }


   // Remove the text box at the specified index
      const newBoxes = boxes.filter((_, i) => i !== index);

      // Update bodyText by removing the specific placeholder
      let updatedBodyText = bodyText;
      const placeholderToRemove = `{{${index + 1}}}`;
      updatedBodyText = updatedBodyText.replace(placeholderToRemove, "").trim();

      // Adjust subsequent placeholders to maintain sequential order
      for (let i = index + 1; i <= boxes.length; i++) {
        const oldPlaceholder = `{{${i + 1}}}`;
        const newPlaceholder = `{{${i}}}`;
        updatedBodyText = updatedBodyText.replace(new RegExp(`\\{\\{${i + 1}\\}\\}`, "g"), newPlaceholder);
      }

      // Update the state with modified boxes and body text
      setBoxes(newBoxes);
      setBodyText(updatedBodyText);
      updateTemplatePreview();
      };
  
  useEffect(() => {
    updateTemplatePreview();
}, [bodyText, boxes, FooterText, file, headerType, buttonData, buttonText, websiteUrl]);


  const updateTemplatePreview = () => {
    let updatedText = bodyText;

    const placeholderRegex = /{{(\d+)}}/g;
    let match;

    while ((match = placeholderRegex.exec(bodyText)) !== null) {
      const index = parseInt(match[1], 10) - 1;

      if (boxes[index] && boxes[index].action) {
        updatedText = updatedText.replace(match[0], boxes[index].action);
      } else {
        updatedText = updatedText.replace(match[0], "");
      }
    }

    // updatedText += `\n\n${FooterText}`;
    setTemplatePreview(updatedText);
  };

  const handleOptionChange = (value: string) => {
    // debugger
    setSelectedOption(value);
    setSelectedFile(null);  
    setTextInput('');      
    setHeaderType(value);
    // debugger
  };


  const handleOptionChange1 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHeaderType(e.target.value);
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (isValidFile(file)) {
        setSelectedFile(file);
      } else {
        alert('Invalid file type selected.');
      }
    }
  };

  const isValidFile = (file: File) => {
    const fileType = file.type;
    if (selectedOption === 'image') {
      return fileType === 'image/jpeg' || fileType === 'image/png';
    }
    if (selectedOption === 'video') {
      return fileType.includes('video/');
    }
    if (selectedOption === 'document') {
      return (
        fileType === 'application/pdf' ||
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        fileType === 'application/vnd.ms-excel' ||
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    }
    return false;
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(e.target.value);
  };


  const handleFooterChange = (value: string) => {
    setFooterText(value); // Directly set the string value
  };

  const isFormValid = () => {
    return (
      templatePlatform &&
      templateList&&
      languageList&&
      headerType &&
      bodyText &&
      FooterText &&
      buttonType &&
      buttonText

    );
  };

 
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value);
  };

  const resetForm = () => {
    setPlatform("");
    setTemplateName("");
    setTemplateType("");
    setLanguage("");
    setHeaderType("");
    setBodyText("");
    setFooterText("");
    setButtonType("");
    setButtonText("");

};



  const handleSubmit = async () => {
    if (
      !validatePlatformName() &&
      !validateTemplateName() &&
      !validateLanguage() &&
      !validateChannel() &&
      !validateBody()
    ) {
      // If validation fails, do not submit
      return;
    }
 
  
      const data={
        "PlatformName": platform,
        "TemplateType": channel,
        "TemplateName": templateName,
        "TemplateLanguage": language,
        "TemplateHeader": headerType,
        "TemplateBody": bodyText,
        "TemplateFooter": FooterText,
        "Components": "String",
        "ButtonType": buttonType,
        "ButtonText": buttonText,
        "CreatedBy": 1,
        "CreatedDate": "2024-09-30T14:35:00",
        "UpdateBy":1,
        "UpdatedDate":"2024-10-05T14:35:00",
        "Status":"Live"
      
      }
      // debugger
      console.log(data);
      // debugger
      try{
      const response = await axios.post(`${apiUrlAdvAcc}/CreateTemplate`, data);
      
      if (response.data.status === 'Success') {
        resetForm();
        toast.success("The template details saved successfully ")
        setTimeout(() =>{
            dispatch(setCreateBreadCrumb(false));
            navigate('/navbar/TemplateList')
        },1000)      
      } else {
          console.error('Upload failed:', response.data.Status_Description);
          toast.error("An error occurred while saving the Template details")
          setTimeout(() =>{/* wait for 1 second */},1000)
      }
    }
    catch(e){
      console.log('datas:', data);
      console.log("Error in submitting form");
    }
  }

const renderPreview = () => {
  // Check if any content is available for the body or footer
  const isBodyEmpty = !bodyText?.trim();
  const isFooterEmpty = !FooterText?.trim();
  const isbuttonTextEmpty =!buttonText?.trim();
  const noContentSelected = !selectedOption && !selectedFile && isBodyEmpty && isFooterEmpty && isbuttonTextEmpty;

  return (
    <div className="flex flex-col justify-between w-full h-full">
      {noContentSelected ? (
        <div className='flex w-full justify-center'>
          <div className='flex-col justify-center text-left mt-[250px] items-center'>
            <div>  
              {textAreaIcon()}
              </div>
              <div className='mt-[30px]'><p className="text-xl font-semibold">Mobile screen</p></div>
              <div className='w-[140px] mt-[10px]' style={{fontWeight: 500 , fontSize: '14px'}}><p className="">Preview varies based on platform selection</p></div>
          </div>
        </div>
    
      ) : (
        <>
          {/* Image, Video, or Document Preview */}
          <div className="flex justify-center">
          {selectedOption === 'image' && selectedFile && (
            <img
              src={URL.createObjectURL(selectedFile!)}
              alt="Preview"
              className="w-[320px] h-[200px] object-cover mx-auto"
            />
          )}
          {selectedOption === 'video' && selectedFile && (
              <video controls className="w-[320px] h-[200px] object-cover mx-auto">
                <source src={URL.createObjectURL(selectedFile)} />
              </video>
            )}
            {selectedOption === 'document' && selectedFile && (
              <p className=" w-[320px] h-[200px] object-cover mx-auto text-sm">Uploaded document: {selectedFile.name}</p>
            )}
        </div>

      {/* Body Text */}
      <div className=" flex-1 bg-white text-black overflow-visible w-full text-left">
      {selectedOption === 'text' && textInput && (  // New text preview section
      <p className="w-full text-base font-sans p-2 font-bold">{textInput}</p>
                        )}
            {!isBodyEmpty ? (
              <p className="text-base font-sans p-2">{templatePreview}</p>
            ) : (
              <p className="text-base font-medium"></p>
            )}
          </div>

          {/* Footer Text */}
          <div className="border-t mt-2 pt-2 text-center">
            {!isFooterEmpty ? (
              <p className="font-semibold text-black">{FooterText}</p>
            ) : (
              <p className="text-sm text-gray-500"></p>
            )}
          </div>

        {/* WebURL */}
        <div className="border-t mt-2 pt-2 text-center">
            {!isbuttonTextEmpty ? (
              <p className="font-serif text-blue-400"><a href={websiteUrl} target="_blank" rel="noopener noreferrer">
              {buttonText}
            </a></p>
            ) : (
              <p className="text-sm text-gray-500"> </p>
            )}
          </div>
          <div className="border-t mt-2"></div>
        </>
      )}
    </div>
  );
};

  
    return (
         <div className="overflow-y-auto">

           <ToastContainer />
           <div className="fixed flex justify-end space-x-3 ml-[calc(70%-135px)] top-[-8px] z-20">
      <Button
        variant={'outline'}
        className="w-[80px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
        onClick={() => {
          dispatch(setCreateBreadCrumb(false));
          navigate('/navbar/templatelist')}}
      >
        Discard
      </Button>
      <Button className="w-fit" onClick={() => {
          if (templateId) {
              handleEdit(); 
          } else {
              handleSubmit(); 
          }
          console.log("Clicked"); 
      }}>
          Save and exit
      </Button>

    </div>
     <div className='p-3 '>
        <div className=" flex flex-col md:flex-row gap-6 mb-[100px]">
          <div className="space-y-6 w-full md:w-3/5 ">
            <div className="border p-4 rounded-lg">
              <h2 className="text-left mb-2" style={{ fontWeight: 600 , fontSize: '16px'}}>Platform</h2>
            <Select
                 value={platform} 
                  onValueChange={(value) => {
                    setPlatform(value);
                    console.log("Selected Template Platform :", value); 
                  }}
                >
                  <SelectTrigger className='text-gray-500'>
                     <SelectValue className='text-gray-500' placeholder={templateId?updateplatform:"Select for which platform you want to build a message template"} /> 
                  </SelectTrigger>
                  <SelectContent>
                    {templatePlatform.map((TemplatePlatform) => (
                      <SelectItem className='text-gray-500' key={TemplatePlatform.platform_id} value={TemplatePlatform.platform_id as any}>
                        {TemplatePlatform.platform_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {PlatformNameError && (
                    <p className="text-red-500 text-xs text-left mt-2 ml-2">{PlatformNameError}</p>
                  )}
                    </div>
                  <div className="border p-4 rounded-lg text-left">
                    <h2 className="text-lg text-left mb-2" style={{ fontWeight: 600 , fontSize: '16px'}} >Template name and language</h2>                   
                    <div className="flex space-x-4 mt-2"> 
                      {/* Template Column */}
                   <div className="w-full">
                   <Label htmlFor="channel" className="mt-2 text-left">
                      Template
                    </Label>
                    <Input
                          type="text"
                          placeholder="Name your message template"
                          className="w-full h-[35px] border rounded-md p-2 mt-2 text-gray-500"
                          value={templateName}
                          onChange={handleInputChange} />
                {templateError && (
                    <p className="text-red-500 text-xs text-left mt-2 ml-2">{templateError}</p>
                  )}
                  </div >
                  {/* Language Column */}
                  <div className="w-full">   
                  <Label htmlFor="channel" className="mt-2 text-left">
                      Language
                    </Label>
                    <Select value={language}
                    onValueChange={(value) => {
                    setLanguage(value);
                    console.log("Selected Language ID:", value); 
                  }} > 
                 
                  <SelectTrigger className='text-gray-500 w-48 mt-2'>
                  <SelectValue className='text-gray-500' placeholder={templateId?updateLanguage:"English"} />
                  </SelectTrigger >
                  <SelectContent>
                   {languageList.map((language) => (
                    <SelectItem className='text-gray-500' key={language.language_id} value={language.language_id as any}> 
                    {language.language_name} 
                    </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {LanguageError && <p className="text-red-500 text-xs mt-2 ml-2">{LanguageError}</p>}
              </div>
              </div>
              <div className="mt-4 text-left">
                <Label htmlFor="channel" className="mt-2 text-left">
                  Channel
                </Label>
                <div className="mt-2"> {/* Added margin-top to create space */}
                <Select
                  value={channel} 
                  onValueChange={(value) => {
                    console.log("Selected Channel ID:", value);
                    setChannel(value);
                  }}
                >
                  <SelectTrigger className="text-gray-500"> {/* Apply gray text color to the trigger */}
                    <SelectValue
                      className="text-gray-500" 
                      placeholder={templateId ? updateChannel : "Select your Template channel"} 
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {channelList.map((channel) => (
                      <SelectItem
                        className="text-gray-500" // Apply gray text color to each select item
                        key={channel.channel_id} 
                        value={channel.channel_id as any} 
                      >
                        {channel.channel_name} 
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {channelError && <p className="text-red-500 text-xs mt-2 ml-2">{channelError}</p>}
                </div>
                {/* {channelError && <p className="text-red-500 text-sm">{channelError}</p>} */}
              </div>
            </div> 


              <div className="border p-4 rounded-lg">
                <h2 className="mb-2 text-left" style={{fontWeight: 600 , fontSize: '16px' , paddingBottom: '10px'}}>Content</h2>
                <h5 className="text-md mb-2 text-left" style={{ fontWeight: 500 , fontSize: '14px'}}>Header 
                <span
      
                    style={{
                      background: '#F0F4F8', // Background color for the badge
                      color: '#64748B', // Fixed font color for testing
                      padding: '2px 10px', // Padding for the badge
                      borderRadius: '9999px', // Fully rounded badge
                      // Border style, matching the background
                      display: 'inline-flex', // Ensures correct alignment
                      alignItems: 'center', // Centers the text vertically
                      height: '20px', // Fixed height
                      marginLeft: '8px', // Space between title and badge
                      fontSize: '14px', // Optional: font size for better visibility
                      fontWeight: 600, // Set font weight for the badge text to bold
                    }}
                >Optional</span>
                </h5>
                <div className="space-y-4">
      
                    <Select value={headerType} 
                      onValueChange={handleOptionChange}
                  
                    // onValueChange={(value)=>{handleOptionChange(value);
                    //   console.log("LoadHeader:" + headerType)
                    // }}

                     >
                        <SelectTrigger className="w-full text-gray-500">
                        <SelectValue placeholder="None" />
                        </SelectTrigger>
                        <SelectContent>
                        <SelectItem value="image">Image</SelectItem>
                        <SelectItem value="text"> Text</SelectItem> 
                        <SelectItem value="document">Document</SelectItem>
                        <SelectItem value="video">Video</SelectItem>
                        </SelectContent>
                    </Select>
               
                    {/* Image, Video, or Document File Upload */}
                    {(selectedOption === 'image' || selectedOption === 'video' || selectedOption === 'document') && (
                        <div className="mt-4">
                        <Input
                            type="file"
                            accept={
                            selectedOption === 'image' ? 'image/jpeg, image/png' :
                            selectedOption === 'video' ? 'video/*' :
                            selectedOption === 'document' ? '.pdf, .doc, .docx, .xls, .xlsx' : ''
                            }
                            onChange={handleFileChange}
                            className="border rounded-md p-2 mb-4 w-full"
                        />
                        {selectedFile && (
                            <div className="mt-2 flex items-center">
                            <p>{selectedFile.name}</p>

                      <button
                      type="button"
                      onClick={handleRemoveFile}
                      className="text-black hover:text-gray-400"
                      aria-label="Delete File"
                    >
                      <FaTimes />
                    </button>

                  </div>
                )}
                 </div> )}

                    {/* Text Input */}
                    {selectedOption === 'text' && (
                        <div className="mt-4">
                        <Textarea
                            value={textInput}
                            onChange={handleTextChange}
                            className="text-gray-500 border rounded-md p-2 mb-4 w-full"
                            rows={4}
                            placeholder="Enter your text here"
                  />
                  </div>
                    )}
                    
                  <div>
                          <label className="block text-left mb-2" style={{ fontWeight: 500 ,fontSize: '14px' }}>Body</label>
                          <textarea 
                            className="w-full border rounded p-2 text-gray-500" 
                            rows={4} 
                            maxLength={1024}
                            value={bodyText}
                            placeholder="Hello"
                            onChange={(e) => setBodyText(e.target.value)} >

                        </textarea>
                        
                        <Typography
                            variant="body2"
                            sx={{
                              color: bodyText.length < 25 ? "error.main" : "text.secondary",
                              mt: 1,
                              textAlign: "left",
                              fontFamily: "Salesforce Sans, sans-serif",
                            }}
                          >
                            {bodyText.length < 25 ? (
                              <p style={{ fontSize: "12px", fontFamily: "Salesforce Sans, sans-serif" }}>
                                The body text must contain at least 25 characters
                              </p>
                            ) : (
                              <p style={{ fontSize: "12px", fontFamily: "Salesforce Sans, sans-serif" }}>
                                Characters: {bodyText.length}/1024
                              </p>
                            )}
                          </Typography>

                  {BodyError && (<p className="text-red-500 text-sm">{BodyError}</p>)}
                  </div>
                  <Container>
                    <Box
                      sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      }}>
                    <Button
                        onClick={addVariable}
                        className="flex items-center px-4 py-2 border border-gray-300 rounded-md text-white font-thin hover:bg-gray-900 shadow-lg"
                        style={{ 
                          width: "150px", 
                          height: "40px", 
                          background: "#3A85F7", 
                          marginTop: "-8px",       
                          marginLeft: "-20px", 
                          fontWeight: "normal",
                        }}
                      >
                <span className="mr-1"> {/* Wrapper span with margin-right */}
                  <PlusIcon className="w-4 h-4" />
                </span>
              Add Variable
              <span className="ml-1"> {/* Wrapper span with margin-left */}
                <ChevronDownIcon className="w-4 h-4" />
              </span>
            </Button>

                 </Box>

                    <div style={{ textAlign: "left", marginTop: "25px" }}>
                        {boxes.map((box, i) => (
                        <div
                            key={i}
                            style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "15px",
                            }}
                        >
                            <Typography variant="body2" sx={{ color: "black", fontSize: "12px" }}>
                            Text box {i + 1}
                            </Typography>
                            <TextField
                            size="small"
                            type="text"
                            variant="standard"
                            sx={{ marginLeft: "10px", flex: 1 }} // You can keep using sx since you're using MUI
                            value={box.action}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateButtonAction(i, e)} // Specify the event type
                            />
                            <IconButton
                            onClick={() => onTextboxDelete(i)}
                            sx={{
                                color: "red",
                                borderRadius: "50%",
                                padding: "8px",
                                "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
                                marginLeft: "10px",
                            }}
                    >
                          <CancelIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </Container>
    
                        <div>
                            <label className="block text-left mb-2" style={{ fontWeight: 500 ,fontSize: '14px'}}>Footer 
                            <span
      
                            style={{
                              background: '#F0F4F8', 
                              color: '#64748B', 
                              padding: '2px 10px', 
                              borderRadius: '9999px', 
                              display: 'inline-flex', 
                              alignItems: 'center', 
                              height: '20px', 
                              marginLeft: '8px', 
                              fontSize: '14px', 
                              fontWeight: 600,
                            }}
                        >Optional</span>
                            </label>
                            {/* <input type="text" 
                            placeholder="Enter text" 
                            className="w-full border rounded p-2" 
                            value={FooterText}
                            onChange={(e) => setFooterText(e.target.value)}
                            /> */}
                            
                      <Select
                         value={FooterText}
                         onValueChange={handleFooterChange}
                      > 
                      <SelectTrigger className="w-full border rounded p-2 text-gray-500">
                        <SelectValue placeholder="Select an option" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Thank You">Thank You</SelectItem>
                        <SelectItem value="Welcome">Welcome</SelectItem>
                      </SelectContent>
                    </Select>

                        </div>
                        </div>
                    </div>

      <Box
            mb={100}
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              p: 2,
              border: "1px solid #e0e0e0", // Define a border to keep it visible
              boxShadow: "none",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "16px",
                color: "black",
                textAlign: "left",
                fontWeight: "bold",
                mb: 1,  
              }}
            >
              Buttons 
              <span
      
      style={{
        background: '#F0F4F8', // Background color for the badge
        color: '#64748B', // Fixed font color for testing
        padding: '2px 10px', // Padding for the badge
        borderRadius: '9999px', // Fully rounded badge
         // Border style, matching the background
        display: 'inline-flex', // Ensures correct alignment
        alignItems: 'center', // Centers the text vertically
        height: '20px', // Fixed height
        marginLeft: '8px', // Space between title and badge
        fontSize: '14px', // Optional: font size for better visibility
        fontWeight: 600, // Set font weight for the badge text to bold
      }}
  >Optional</span>
            </Typography>

            <div className="inline-block mt-4 ml-4 w-full flex-col justify-start">
              <div className='w-full flex justify-start'>
              <Button
              onClick={handleDropdownToggle}
              className="flex items-center px-4 py-2 border border-gray-300 font-thin rounded-md text-white hover:bg-gray-900 shadow-lg"
              style={{ 
                width: "150px", 
                height: "40px", 
                background: "#3A85F7", 
                marginTop: "-8px",       
                marginLeft: "-8px", 
                fontWeight: "normal",
              }}
            >
                <span className="mr-1"> {/* Wrapper span with margin-right */}
                  <PlusIcon className="w-4 h-4" />
                </span>

              Add button
              <span className="ml-1"> {/* Wrapper span with margin-left */}
              <ChevronDownIcon className="w-4 h-4" />
            </span>
            </Button>

              </div>
              <div className="mt-5"></div>
       

        <div className="rounded-lg text-left ">
          {showDropdown && (
            <div className="mb-4 flex w-[500px]" style={{ justifyContent: 'flex-start' , marginLeft: '-10px' }}>
              <div className="w-[140px] pr-4 block text-sm font-medium">
                <label htmlFor="action-type" 
                      className="mt-5 w-full font-bold text-center">
                           Type of action
                          </label>
                <Select
                  value={buttonType} 
                  onValueChange={(value) => {
                    console.log("updateButtonType::" +updateButtonType);
                    console.log("Selected Button Type ID:", value);
                   
                    setButtonType(value);
                  }}
                >
                  <SelectTrigger className="py-2 px-3 text-sm" style={{ marginTop: '10px' }}> {/* Apply gray text color to the trigger */}
                    <SelectValue
                      className="" 
                      placeholder={templateId ? updateButtonType : "Select Action"} 
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {buttonList.map((GetButtonTypeList) => (
                      <SelectItem
                        className="" 
                        key={GetButtonTypeList.button_id} 
                        value={GetButtonTypeList.button_id  as any} 
                      >
                        {GetButtonTypeList.button_type} 
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
  
              <div className="w-[140px] pr-4 block text-sm font-medium">
                <label
                  htmlFor="button-text"
                  className="block text-sm font-medium text-gray-700"
                >
                  Button text
                </label>
                <input
                  type="text"
                  id="button-text"
                  className="mt-2 block w-full px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  style={{ paddingTop: '0.5rem', paddingBottom: '0.4rem' }}
                  value={buttonText}
                  onChange={(e) => setButtonText(e.target.value)}
                />
              </div>
  
              <div className="w-[140px] pr-4 block text-sm font-medium">
                <label
                  htmlFor="url-type"
                  className="block text-sm font-medium text-gray-700"
                >
                  URL Type
                </label>
                <select
                  id="url-type"
                  className="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={buttonTypeDropdown}
                  onChange={(e) => setButtonTypeDropdown(e.target.value)}
                >
              
                  <option value="static">Static</option>
                  <option value="dynamic">Dynamic</option>
                </select>
              </div>
  
              <div className="w-[140px] block text-sm font-medium">
                <label
                  htmlFor="website-url"
                  className="block text-sm font-medium text-gray-700"
                >
                  Website URL
                </label>
                <input
                  type="text"
                  id="website-url"
                  className="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                />
              </div>
            </div>
          
          )}
        </div>
        <div className=''> {/* Apply negative top margin here */}
        <p className="text-gray-600 text-left ml-[-10px] mt-4 leading-5" style={{ width: "450px", fontSize: "0.875rem", lineHeight: "1.4rem" }}>
              Create buttons that let customers respond to your message or take action.
              You can add up to ten buttons. If you add more than three buttons, they
              will appear in a list.
            </p>
        </div>
      </div>
      </Box>
     </div>

     <div className="border justify-items-center pl-7 pr-7 h-[720px] rounded-lg">
              <h2 className="mb-2 mt-4 ml-[-200px] font-bold">Template preview</h2>
              <div className="flex flex-col justify-between rounded-[50px] text-black p-4 w-[350px] min-h-[640px]"  style={{ backgroundColor: '#f9f9f9'}} >
                <div className="justify-center">
                  <i className="fas fa-mobile-alt text-4xl mb-4"></i>
                  {renderPreview()}
                </div>
                  </div>
                </div>
            </div>  
            </div>
        </div>
    );
};

export default CreateTemplate;
