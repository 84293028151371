import React, { ChangeEvent, FC, useState } from "react";


const Billing: FC = () => {

    return (
        <div className="flex h-screen">
        </div>
      );
};


export default Billing;
