import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button } from "../Components/ui/button";
import { Activity, Check, DollarSign, SendHorizonal, Users } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../Components/ui/card";
import { Input } from "../Components/ui/input";
import { Label } from "../Components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Components/ui/select";

import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../Components/ui/chart";
import { CalendarIcon, PaperPlaneIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";

import { cn } from "../lib/utils";
import { Calendar } from "../Components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../Components/ui/popover";
import axios from "axios";
import { Skeleton } from "../Components/ui/skeleton";
import config from "../config.json";

export function DatePickerWithRange({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: new Date(), // Current date
    to: addDays(new Date(), 20), // Current date + 20 days
  });

  return (
    <div className={cn("flex justify-end gap-2 pb-4 ", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !date && "text-muted-foreground text-[#020617] border-red-500"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" style={{color:'#020617'}} />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

const chartData = [
  { month: "January", SMS: 186, WhatsApp: 80 },
  { month: "February", SMS: 305, WhatsApp: 200 },
  { month: "March", SMS: 237, WhatsApp: 120 },
  { month: "April", SMS: 73, WhatsApp: 190 },
  { month: "May", SMS: 209, WhatsApp: 130 },
  { month: "June", SMS: 214, WhatsApp: 140 },
];

// const chartConfig = {
//   SMS: {
//     label: "SMS",
//     color: "hsl(var(--chart-1))",
//   },
//   WhatsApp: {
//     label: "WhatsApp",
//     color: "hsl(var(--chart-2))",
//   },
// } satisfies ChartConfig

interface ChartData {
  month: string;
  email: number;
  sms: number;
  pushNotifications: number;
  rcSmessages: number;
  whatsapp: number;
}

interface DashChartProps {
  data: ChartData[]; // Expecting an array of ChartData objects
}

const DashChart: FC<DashChartProps> = ({ data }) => {
  const chartConfig = {
    sms: {
      label: "sms",
      color: "hsl(var(--chart-1))",
    },
    whatsApp: {
      label: "whatsApp",
      color: "hsl(var(--chart-2))",
    },
    email: {
      label: "email",
      color: "hsl(var(--chart-3))",
    },
    pushNotifications: {
      label: "pushNotifications",
      color: "hsl(var(--chart-4))",
    },
    rcSmessages: {
      label: "rcSmessages",
      color: "hsl(var(--chart-5))",
    },
  } satisfies ChartConfig;

  return (
    // <Card className="mt-[20px] w-full md:w-[400px] lg:w-[500px] xl:w-[1000px] h-fit relative">
    <Card className="mt-[20px] w-[100%] h-fit relative">
      <CardHeader className="text-left">
        <CardTitle className="text-[#1C2024]">Sent messages</CardTitle>
        <CardDescription className="text-[#60646C] font-normal">Showing total messages sent per month</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} className="w-full h-[200px]">
          <AreaChart
            accessibilityLayer
            data={data} // Use the passed data prop here
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            {/* Define the areas for each data type */}
            <Area
              dataKey="email"
              type="natural"
              fill="var(--color-email)"
              fillOpacity={0.4}
              stroke="var(--color-email)"
              stackId="a"
            />
            <Area
              dataKey="sms"
              type="natural"
              fill="var(--color-sms)"
              fillOpacity={0.4}
              stroke="var(--color-sms)"
              stackId="a"
            />
            <Area
              dataKey="pushNotifications"
              type="natural"
              fill="var(--color-pushNotifications)"
              fillOpacity={0.4}
              stroke="var(--color-pushNotifications)"
              stackId="a"
            />
            <Area
              dataKey="rcSmessages"
              type="natural"
              fill="var(--color-rcSmessages)"
              fillOpacity={0.4}
              stroke="var(--color-rcSmessages)"
              stackId="a"
            />
            <Area
              dataKey="whatsApp"
              type="natural"
              fill="var(--color-whatsApp)"
              fillOpacity={0.4}
              stroke="var(--color-whatsApp)"
              stackId="a"
            />
            <ChartLegend content={<ChartLegendContent />} />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

interface CardProps {
  title: string;
  value: string;
  change: string;
  icon?: ReactNode;
}

const CardComponent: FC<CardProps> = ({ title, value, change, icon }) => {
  return (
    <Card className="w-full md:w-[200px] lg:w-[220px] xl:w-[240px] h-fit relative flex-grow border-[#E2E8F0]">
      <div className="p-1 pl-0 pr-0">
        {/* <DollarSign className='absolute top-7 right-6 text-gray-400'/> */}
        {icon && (
          <div className="absolute top-7 right-6 text-gray-400">{icon}</div>
        )}

        <CardHeader className="text-left pb-2">
          <CardTitle className="text-[14px] text-[#020617] font-medium leading-[20px] text-left">{title}</CardTitle>
        </CardHeader>
        {/* <CardContent className="text-left text-2xl font-bold "> */}
        <CardContent className="text-left text-[#020617] text-2xl font-bold leading-[24px] mt-1">
          {value}
          {/* <div className="text-sm text-gray-400 font-medium "> */}
          <div className="text-[12px] text-[#64748B] font-normal leading-[20px] mt-[2px]">
            {change}
          </div>
        </CardContent>
      </div>
    </Card>
  );
};

const SkeletonCard: FC = () => {
  return (
    <div className="flex-col">
      <div className="flex flex-wrap gap-2">
        <Card className="w-full md:w-[200px] lg:w-[220px] xl:w-[240px] h-fit relative">
          <Skeleton className="absolute top-5 right-2 text-gray-200" />
          <CardHeader className="text-left">
            <Skeleton className="h-4 w-[100px]" />
          </CardHeader>
          <CardContent className="text-left text-2xl font-bold">
            <Skeleton className="h-4 mt-2 w-[100px]" />
            <div className="text-sm text-gray-400 font-medium">
              <Skeleton className="h-4 w-[100px]" />
            </div>
          </CardContent>
        </Card>
      </div>
      <Skeleton />
    </div>
  );
};

const SkeletonChart: FC = () => {
  return (
    <Card className="mt-[20px] w-full md:w-[400px] lg:w-[500px] xl:w-[1000px] h-fit relative">
      <CardHeader className="text-left">
        <CardTitle>
          <Skeleton className="w-full h-" />
        </CardTitle>
        <CardDescription>
          <Skeleton className="w-[200px] h-4" />
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={{}} className="w-full h-[200px]">
          <Skeleton className="w-full h-full" />
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

const Dashboard: FC = () => {
  const [chartData, setChartData] = useState<any>();
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setApiUrlAdvAcc(config.ApiUrlAdvAcc); // Set the API URL from config
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    loadConfig();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (apiUrlAdvAcc) {
        // Ensure apiUrlAdvAcc is valid
        try {
          console.log("apiUrlAdvAcc", apiUrlAdvAcc); // For debugging
          const response = await axios.get(
            `${apiUrlAdvAcc}/GetCombinedStatistics`
          );
          console.log("API Response:", response.data); // Check the response
          setChartData(response.data);
        } catch (error) {
          console.error("Error fetching the statistics:", error);
        }
      }
    };

    fetchData();
  }, [apiUrlAdvAcc]); // Depend on apiUrlAdvAcc

  const fetchData = async () => {
    try {
      console.log("apiUrlAdvAcc", apiUrlAdvAcc);
      const response = await axios.get(`${apiUrlAdvAcc}/GetCombinedStatistics`);
      console.log("apiUrlAdvAcc 2", `${apiUrlAdvAcc}/GetCombinedStatistics`);
      setChartData(response.data);
      console.log("chart data : ", chartData.campaignDetails[0].totalCampaigns);
      console.log(typeof response.data);
    } catch (error) {
      console.error("Error fetching the statistics:", error);
    }
  };

  return chartData ? (
    <div className="flex-col w-full">
      <div className="flex mt-[-15px] justify-end">
        <div>
          <DatePickerWithRange />
        </div>
        <div>
          <Button className="ml-2 w-fit font-normal text-[#020617]" variant={"outline"}>
            By Week
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 w-full justify-between border-orange-600">
        {/* <CardComponent
          title="Campaigns"
          value={chartData?.campaignDetails[0]?.totalCampaigns || 0}
          change="+20.1 from last month"
          icon={<PaperPlaneIcon />}
        /> */}
        <CardComponent title="Campaigns" value="261" change="+20.1 from last month"  icon={<PaperPlaneIcon className="text-[#64748B]"/>}  />
        <CardComponent
          title="Recipients"
          value="5432"
          change="-15.6 from last month"
          icon={<Users className="text-[#64748B]" size={20} />}
        />
        <CardComponent
          title="Sent"
          value={chartData?.messagesSentDetails[0]?.totalSent || 0}
          change="+30.2 from last month"
          icon={<Check className="text-[#64748B]" size={20} />}
        />
        <CardComponent
          title="Delivery rate"
          value="45.6%"
          change="+2.1 from last month"
          icon={<Activity className="text-[#64748B]" size={20}/>}
        />
      </div>
      <DashChart data={chartData?.chartDetails} />
    </div>
  ) : (
    <div className="flex-col w-full">
      <div className="flex mt-[-15px] justify-end">
        <div>
          <DatePickerWithRange />
        </div>
        <div>
          <Button className="ml-2 w-fit font-normal" variant={"outline"}>
            By Week
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 w-full justify-between">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
      <SkeletonChart />
    </div>
  );
};

export default Dashboard;
