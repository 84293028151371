import React, { useState, ChangeEvent, useEffect } from 'react';
import { Typography, Card } from '@mui/material';
import { Input } from 'src/Components/ui/input';
import { Button } from 'src/Components/ui/button';
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import config from  '../../config.json';
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { RootState } from 'src/State/store';
import { Image } from 'lucide-react';



const Profile: React.FC = () => {
  const [name, setName] = useState('Sebastian Swaczynski');
  const [email, setEmail] = useState('');
  const [repeatEmail, setRepeatEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [fullName, setFullName] = useState('');


  const navigate = useNavigate();
  const id=localStorage.getItem("userid");
  const mailId = useSelector((state:RootState)=>state.authentication.userEmail);
  


  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setProfilePicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAccountDeletion = () => {
    toast.error('Your account has been deleted!');
  };
  
  const GetUserNameByEmail = async () => {


    const data = {
      
        "userEmail": mailId  
    };

    console.log('Request Data:', data);
    console.log('URL:' , config.API_URL+"/GetUserNameByEmail");

    try {
      const response = await axios.post(config.API_URL + "/GetUserNameByEmail", data);

      if (response.data && response.data.length > 0 && response.data[0].Status === "Success") {
          const userFirstName = response.data[0].First_Name;  
          const userLastName = response.data[0].Last_Name;   

          setFirstName(userFirstName);
          setLastName(userLastName);

          setFullName(`${userFirstName} ${userLastName}`); 
          console.log('Full Name:', fullName);
          console.log('userFirstName:', userFirstName);
          console.log('userLastName:', userLastName);
          
      } else {
       
          console.error('Failed to get user details:', response.data[0]?.Status_Description);
      }

  } catch (error) {
        console.log('Request Data:', data);
        console.error('Error in submitting form:', error);
    }
}



const handleProfileUpdate = async () => {

          const nameParts = fullName.split(" ");

          const Fname = nameParts[0] || ""; 
          const Lname=nameParts.slice(1).join(" ") || ""; 

  try{

    const data={
      "UserEmail": mailId,  
      "firstName" : Fname,
      "lastName" : Lname

    }
    console.log("data:" ,data);
    console.log ("API:" , "http://localhost:5008/Authentication/api/UpdateUserProfile/updateProfile", data);
          
      
      const response = await axios.put("http://localhost:5008/Authentication/api/UpdateUserProfile/updateProfile", data);

          console.log("res:" , response)
      if (response.data.status === 'Success') {
          // toast.success("The Profile Updated successfully");
          toast.success("Profile Updated Successfully", {
            position: "top-right",  // Set notification to appear in the top right corner
            autoClose: 3000,  // Notification will close after 3 seconds
            hideProgressBar: true,  // You can disable the progress bar
            closeOnClick: true,  // Close on click
          });
          setTimeout(() => {
          
          }, 3000);
      } else {
          console.error('Update failed:', response.data.Status_Description);
      }
    } catch (e) {
      console.error("Error in Profile update", e);
    }
    
  };



  useEffect(() => {
   const id=localStorage.getItem("userid");
   console.log("id :"+ id);
   GetUserNameByEmail();
   setFullName(fullName);
    console.log(" Redux mail id: "+mailId);
  }, []); 


       
  const handleEmailUpdate = async () => {
    // Check if email and repeatEmail match before proceeding
     if (email !== repeatEmail) {
       setEmailError('Emails do not match!');
       return; // Stop further execution if emails don't match
     }

     // If emails match, clear the error and proceed with the API call
     setEmailError('');
   

try{
 console.log("existingEmail:" + mailId);
 console.log("newEmail:" + email);
const data={
"existingEmail": mailId,  
"newEmail" : email
}
console.log("data:" ,data);
console.log ("API:" , "http://localhost:5008/Authentication/api/UpdateUserEmailAddress/updateEmailAddress", data);
 

const response = await axios.put("http://localhost:5008/Authentication/api/UpdateUserEmailAddress/updateEmailAddress", data);

 console.log("res:" , response)
if (response.data.status === 'Success') {
 
 toast.success("Your Email Address Updated successfully", {
   position: "top-right",  // Set notification to appear in the top right corner
   autoClose: 3000,  // Notification will close after 3 seconds
   hideProgressBar: true,  // You can disable the progress bar
   closeOnClick: true,  // Close on click
 });
 setTimeout(() => {
 
 }, 3000);
} else {
 console.error('Update failed:', response.data.Status_Description);
}
} catch (e) {
console.error("Error in Email update", e);
}

 };

  const handlePasswordUpdate = () => {
    setPasswordError('');
    if (newPassword === repeatNewPassword) {
      toast.success('Password updated!');
    } else {
      setPasswordError('New passwords do not match!');
    }
  };

  return (
    <div className="flex h-screen"> 
      <div className="flex-grow p-2 overflow-y-auto no-scrollbar">


         {/* Profile Picture */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl ">
          <Typography component="h2" className="text-xl font-bold mb-4"><b>Your profile picture</b></Typography>
          <Typography className="mb-4 mt-1" style={{fontSize:'12px', color:'#64748B'}}>Please choose a photo to upload as your profile picture.</Typography>
          {/* <input 
            type="file" 
            accept="image/*" 
            onChange={handleImageUpload} 
            className="mb-4"
          />
          {imagePreview && (
            <div className="mb-4 w-32 border border-black overflow-hidden rounded"> 
              <img 
                src={imagePreview} 
                alt="Profile Preview" 
                className="w-full h-auto max-h-32 object-contain" 
              />
            </div>
          )} */}
          <div className="flex items-center gap-2 mt-4 cursor-pointer" 
            onClick={() => document.getElementById('file-upload')?.click()}>
            <div>
              {(!imagePreview && <Image className='h-[24px] w-[24px]'/>)
              || (imagePreview && <img src={imagePreview} className='h-[24px]'/>)}
              
            </div>
            <div className='flex-col'>
              <Typography style={{fontSize:'14px'}}>
                <b>Upload your profile picture</b>
              </Typography>
              <Typography style={{fontSize:'12px'}}>
              Choose a photo to upload as your profile picture.
              </Typography>
            </div>
          </div>
          <input 
            id="file-upload"
            type="file" 
            accept="image/*" 
            onChange={handleImageUpload} 
            className="hidden"
          />
        </Card>
    
        <ToastContainer />

        {/* Profile Information Section */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
          <Typography component="h3" className="text-xl font-bold mb-4"><b>Your name</b></Typography>
          <p className='text-[12px] text-gray-500'>Update your name to be displayed on your profile.</p>

          <Input className="mb-4 mt-4" 
          value={fullName} 
          onChange={(e) => {
            console.log("Input value changed:", e.target.value);
            setFullName(e.target.value)
          }
            
          } 
          placeholder="Your name" 
          aria-label="Your name" />

          <Button onClick={handleProfileUpdate} disabled={isLoading} className="py-1 px-3 text-sm w-[128px]">
            {isLoading ? 'Updating...' : 'Update profile'}
          </Button>
        </Card>
    


        {/* Update Email Section */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
          <Typography component="h3" className="text-xl font-bold mb-4"><b>Update your email</b></Typography>
          <Typography component="p" className="text-gray-500 mb-4">Update your email address you use to login to your account.</Typography>
          <Input className="mb-4 mt-4" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your new email" aria-label="Your new email" />
          <Input className="mb-4 mt-4" value={repeatEmail} onChange={(e) => setRepeatEmail(e.target.value)} placeholder="Repeat email" aria-label="Repeat email" />
          {emailError && <Typography className="text-red-500 mb-4">{emailError}</Typography>}
          <Button onClick={handleEmailUpdate} className="py-2 px-4 text-sm w-[178px]">Update email address</Button>
        </Card>
    



        {/* Update Password Section */}
        <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
          <Typography component="h3" className="text-xl font-bold mb-4"><b>Update your password</b></Typography>
          <Input className="mb-4 mt-4" type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Current password" aria-label="Current password" />
          <Input className="mb-4 mt-4" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="New password" aria-label="New password" />
          <Input className="mb-4 mt-4" type="password" value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)} placeholder="Repeat new password" aria-label="Repeat new password" />
          {passwordError && <Typography className="text-red-500 mb-4">{passwordError}</Typography>}
          <Button onClick={handlePasswordUpdate} className="py-2 px-4 text-sm w-[150px]">Update password</Button>
        </Card>
    
         {/* Delete Account Section */}
            <Card className="mb-8 p-6 border border-grey text-left max-w-xl border-2 border-red-600">
            <Typography component="h3" className="mb-4"
                          style={{ 
                          fontWeight: 600, 
                          fontSize: '14px', 
                          lineHeight: '24px', 
                          color: '#020617', 
                          letterSpacing: '-1.5%' 
              
              }}> Danger zone</Typography>
              <Typography component="p" className= "text-gray-500 mb-4" style={{fontWeight: 400 , fontSize:'14px' , lineHeight: '20px'}}>
              Some actions cannot be undo. Please be careful.
              </Typography>
              <Typography component="h3" className="mb-4"
               style={{ 
                fontWeight: 600, 
                fontSize: '14px', 
                lineHeight: '24px', 
                color: '#020617', 
                letterSpacing: '-1.5%' 
    
              }}>Delete team</Typography>
              <Typography component="p" className="text-gray-500 mb-4" style={{fontWeight: 400 , fontSize:'14px' , lineHeight: '20px'}}>
                This action cannot be undone. All data associated with this team will be deleted.
              </Typography>
              <Button 
                onClick={handleAccountDeletion} 
                className="bg-red-600 text-white py-2 px-4 text-sm hover:bg-red-700 w-[167px]" >
                Delete your account
              </Button>
            </Card>
        <div className="mb-20" />
      </div>
    </div>
  );
  
  
  
  
};

export default Profile;
