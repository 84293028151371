import { Button } from "../../Components/ui/button";
import { Card } from "../../Components/ui/card";
import { Label } from "../../Components/ui/label";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "../../Components/ui/select";
import { Input } from "../../Components/ui/input";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useState, SetStateAction, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon component
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { Tabs, TabsContent } from "../../Components/ui/tabs";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format, startOfMonth } from "date-fns";
import { DateRange } from "react-day-picker";
import { Calendar } from "../../Components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Components/ui/popover";
import { cn } from "../../lib/utils";
import { useDispatch } from "react-redux";
import { setCreateBreadCrumb } from "../../State/slices/AdvertiserAccountSlice";

interface AudienceCardProps {
  selectedRecipients: number;
  totalRecipients: number;
}

// Define the Country type
interface Country {
  country_id: number;
  country_name: string;
}

interface Template {
  template_id: number;
  template_name: string;
  channel_type: string;
}

interface Channel {
  channel_id: number;
  channel_name: string;
}

interface DatePickerWithRangeProps {
  className?: string;
}

const DatePickerWithRange: React.FC<DatePickerWithRangeProps> = ({
  className,
}) => {
  // Initialize the date range with the 1st of the current month and today's date
  const [date, setDate] = useState<{ from: Date; to: Date }>({
    from: startOfMonth(new Date()), // First day of the current month
    to: new Date(), // Today
  });

  return (
    <div className={className}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={`w-[254px] justify-start text-left font-normal mt-0 ${
              !date.from && !date.to ? "text-muted-foreground" : ""
            }`}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date.from ? (
              date.to ? (
                `'dd-mm-yyyy'`
              ) : (
                format(date.from, "dd-MM-yyyy")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            selected={date}
            onSelect={(range) => setDate(range as { from: Date; to: Date })}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default function CreateCampaign() {
  const [campaignName, setCampaignName] = useState<string>("");
  const [channelList, setChannelList] = useState<Channel[]>([]); // State for the channel list
  const [templatefilterlist, setTemplatefilterlist] = useState<Template[]>([]);
  const [channel, setChannel] = useState("");
  const [templateList, setTemplateList] = useState<Template[]>([]);
  const [template, setTemplate] = useState("");
  const [reachPeopleFrom, setReachPeopleFrom] = useState("");
  const [reachPeopleIn, setReachPeopleIn] = useState("");
  const [campaignBudget, setCampaignBudget] = useState<string>("");
  const [campaignStartDate, setCampaignStartDate] = useState<string>("");
  const [campaignEndDate, setCampaignEndDate] = useState<string>("");
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [updateChannel, setUpdateChannel] = useState("");
  const [updateTemplate, setUpdateTemplate] = useState("");
  const [updateCountry, setUpdateCountry] = useState("");
  const [updateRoamingCountry, setUpdateRoamingCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const selectedRecipients = 1240;
  const totalRecipients = 3448;
  const percentage = Math.round((selectedRecipients / totalRecipients) * 100);
  const navigate = useNavigate();
  const location = useLocation();
  const campaignId = location.state?.campaignId || "";
  const channelName = location.state?.channelType || "";
  const [campaignNameError, setCampaignNameError] = useState<string | null>(
    null
  );
  const [channelError, setChannelError] = useState<string | null>(null);
  const [templateError, setTemplateError] = useState<string | null>(null);
  const [budgetError, setBudgetError] = useState<string | null>(null);
  const [startdateError, setStartDateError] = useState<string | null>(null);
  const [enddateError, setEndDateError] = useState<string | null>(null);
  const [targetCountryError, setTargetCountryError] = useState<string | null>(
    null
  );
  const [roamingCountryError, setRoamingCountryError] = useState<string | null>(
    null
  );
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");
  const [isStartCalendarOpen, setStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setEndCalendarOpen] = useState(false);
  const dispatch =useDispatch();

  const handleStartDateChange = (date: Date | undefined) => {
    if (date) {
      setCampaignStartDate(formatDate(date));
    }
    setStartCalendarOpen(false); // Close the calendar after selecting a date
  };

  const handleEndDateChange = (date: Date | undefined) => {
    if (date) {
      setCampaignEndDate(formatDate(date));
    }
    setEndCalendarOpen(false); // Close the calendar after selecting a date
  };

  const formatingDate = (dateString: string) => {
    debugger;
    const [day, month, year] = dateString.split("/").map(Number);
    // Create a new Date object (months are zero-indexed in JS)
    // Create a new Date object in UTC
    const dateUTC = new Date(Date.UTC(year, month - 1, day));
    debugger;
    return dateUTC.toISOString();
    debugger;
  };

  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-11) and pad
    const year = date.getFullYear(); // Get the full year

    return `${day}/${month}/${year}`; // Return formatted date string
  };

  // Fetch config on component mount
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        console.log("Config loaded:", config); // Debugging log
        setApiUrlAdvAcc(config.ApiUrlAdvAcc); // Set API URL from config
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []); // Runs only once on mount

  // Watch for apiUrlAdvAcc and campaignId to change and fetch data
  useEffect(() => {
    const fetchData = async () => {
      if (apiUrlAdvAcc) {
        console.log(
          "Fetching data for apiUrlAdvAcc:",
          apiUrlAdvAcc,
          "campaignId:",
          campaignId
        ); // Debugging log
        try {
          await getChannelList(); // Load the channel list
          await getCountryList(); // Fetch countries
          await getTemplateList(); // Fetch templates
          if (campaignId) {
            await loadCampaignList(campaignId); // Load campaign details
          }
          console.log("Data fetched successfully");
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.log("apiUrlAdvAcc or campaignId is missing", {
          apiUrlAdvAcc,
          campaignId,
        }); // Log to help debug
      }
    };

    fetchData();
  }, [apiUrlAdvAcc]);

  const validateCampaignName = () => {
    if (!campaignName.trim()) {
      setCampaignNameError("Campaign name is required");
      return false;
    }
    setCampaignNameError(null);
    return true;
  };

  const validateChannel = () => {
    if (!channel && !updateChannel) {
      // Ensure either selected channel or updateChannel exists
      setChannelError("Please select a channel");
      return false;
    }
    setChannelError(null);
    return true;
  };

  const validateTemplate = () => {
    if (!template && !updateTemplate) {
      // Ensure either selected template or updateTemplate exists
      setTemplateError("Please select a template");
      return false;
    }
    setTemplateError(null);
    return true;
  };

  const validateFromCountry = () => {
    if (!reachPeopleFrom && !updateCountry) {
      // Ensure either selected template or updateTemplate exists
      setTargetCountryError("Please select a country");
      return false;
    }
    setTargetCountryError(null);
    return true;
  };

  const validateInCountry = () => {
    if (!reachPeopleIn && !updateRoamingCountry) {
      // Ensure either selected template or updateTemplate exists
      setRoamingCountryError("Please select a country");
      return false;
    }
    setRoamingCountryError(null);
    return true;
  };

  const validateBudget = () => {
    const parsedBudget = parseFloat(campaignBudget);
    if (!campaignBudget || isNaN(parsedBudget) || parsedBudget <= 0) {
      setBudgetError("Please enter a valid campaign budget");
      return false;
    }
    setBudgetError(null);
    return true;
  };

  const validateDates = (): boolean => {
    let isValid = true;

    if (!campaignStartDate) {
      setStartDateError("Start date is required");
      isValid = false;
    } else {
      setStartDateError(null); // Clear the start date error if it's valid
    }

    if (!campaignEndDate) {
      setEndDateError("End date is required");
      isValid = false;
    } else if (new Date(campaignEndDate) < new Date(campaignStartDate)) {
      setEndDateError("End date cannot be earlier than start date");
      isValid = false;
    } else {
      setEndDateError(null); // Clear the end date error if it's valid
    }

    return isValid;
  };

  const resetForm = () => {
    setCampaignName("");
    setCampaignBudget("");
    setTemplate("");
    setReachPeopleFrom("");
    setReachPeopleIn("");
    setChannel("");
    setCampaignStartDate("");
    setCampaignEndDate("");
  };

  const getCountryList = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetCountryList`);

      // Assuming the response data contains a 'CountryList' field as discussed earlier
      if (response.data && response.data.countryList) {
        setCountryList(response.data.countryList);
        console.log("Country List : ", response.data.countryList);
      } else {
        console.log("No country list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching country list:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTemplateList = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetTemplateList`);

      if (response.data && response.data.templateList) {
        setTemplateList(response.data.templateList);
        console.log("Template List : ", response.data.templateList);
      } else {
        console.log("No template list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching template list:", error);
    } finally {
      setLoading(false);
    }
  };

  const getChannelList = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetChannelList`);

      if (response.data && response.data.channelList) {
        setChannelList(response.data.channelList);
        console.log("Channel List : ", response.data.channelList);
      } else {
        console.log("No channel list available in response.");
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching country list:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadCampaignList = async (id: any) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${apiUrlAdvAcc}/GetCampaignDetailsById?CampaignId=` + id
      );

      if (response.data && response.data.campaignDetails) {
        const campaignDetailslocal = response.data.campaignDetails[0];

        console.log("Campaign List By Id:", campaignDetailslocal);

        setCampaignName(campaignDetailslocal.campaign_name);
        setCampaignBudget(campaignDetailslocal.campaign_budget); // Set other fields as needed
        setUpdateChannel(campaignDetailslocal.channel_type);
        setUpdateTemplate(campaignDetailslocal.template_name);
        setUpdateCountry(campaignDetailslocal.target_country);
        setUpdateRoamingCountry(campaignDetailslocal.roaming_country);

        // Format and set the start date using handleDateChange
        const formattedStartDate =
          campaignDetailslocal.start_date_time.split("T")[0];
        handleStartDateChange(new Date(formattedStartDate)); // Call handleDateChange for the start date

        // Format and set the end date using handleDateChange
        const formattedEndDate =
          campaignDetailslocal.end_date_time.split("T")[0];
        handleEndDateChange(new Date(formattedEndDate)); // Call handleDateChange for the end date
      } else {
        console.log("No campaign details available in response.");
      }
    } catch (error) {
      console.error("Error fetching campaign details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (
      !validateCampaignName() &&
      !validateChannel() &&
      !validateTemplate() &&
      !validateFromCountry() &&
      !validateInCountry() &&
      !validateBudget() &&
      !validateDates()
    ) {
      // If validation fails, do not submit
      return;
    }
    try {
      debugger;
      // const
      //  channelId = channelList.find(
      //   (data) => data.channel_name === channel
      // );
      debugger;
      const data = {
        CampaignName: campaignName,
        CampaignBudget: campaignBudget,
        ChannelType: channel,
        TargetCountry: reachPeopleFrom,
        RoamingCountry: reachPeopleIn,
        StartDateTime: formatingDate(campaignStartDate),
        EndDateTime: formatingDate(campaignEndDate),
        status: "Active",
        TemplateName: template,
        CreatedBy: 1,
        UpdatedBy: 1,
      };
      debugger;
      console.log(data);
      debugger;
      const response = await axios.post(`${apiUrlAdvAcc}/CreateCampaign`, data);

      if (response.data.status === "Success") {
        resetForm();
        toast.success("The campaign details saved successfully ");
        setTimeout(() => {
          dispatch(setCreateBreadCrumb(false));
          navigate("/navbar/campaignlist");
        }, 1000);
      } else {
        console.error("Upload failed:", response.data.Status_Description);
        toast.error("An error occurred while saving the campaign details");
        setTimeout(() => {
          /* wait for 1 second */
        }, 1000);
      }
    } catch (e) {
      console.log("Error in submitting form");
    }
  };

  const handleEdit = async () => {
    console.log("channel : " + channel + "updatechannel : " + updateChannel);

    // Determine whether to use 'channel' or 'updateChannel'
    const channelToFind = channel || updateChannel;

    // Find the selected channel from the list
    const selectedChannel = channelList.find(
      (findchannel) => findchannel.channel_name === channelToFind
    );

    // Get the channel ID, or null if not found
    const updateChannelId = selectedChannel ? selectedChannel.channel_id : null;

    // const updateChannelId= channelList.find((data)=>data.channel_name===channel);

    const selectedTemplate = templateList.find(
      (findtemplate) => findtemplate.template_name === updateTemplate
    );
    const updateTemplateId = selectedTemplate
      ? selectedTemplate.template_id
      : null;

    const selectedCountry = countryList.find(
      (findcountry) => findcountry.country_name === updateCountry
    );
    const updateCountryId = selectedCountry ? selectedCountry.country_id : null;

    const selectedRoamingCountry = countryList.find(
      (findroamingcountry) =>
        findroamingcountry.country_name === updateRoamingCountry
    );
    const updateRoamingCountryId = selectedRoamingCountry
      ? selectedRoamingCountry.country_id
      : null;

    if (
      !validateCampaignName() &&
      !validateChannel() &&
      !validateTemplate() &&
      !validateFromCountry() &&
      !validateInCountry() &&
      !validateBudget() &&
      !validateDates()
    ) {
      return;
    }

    try {
      const data = {
        CampaignId: campaignId,
        CampaignName: campaignName,
        CampaignBudget: campaignBudget,
        ChannelType: updateChannelId ? updateChannelId : channel,
        TargetCountry:
          reachPeopleFrom == "" ? updateCountryId : reachPeopleFrom,
        RoamingCountry:
          reachPeopleIn == "" ? updateRoamingCountryId : reachPeopleIn,
        StartDateTime: formatingDate(campaignStartDate),
        EndDateTime: formatingDate(campaignEndDate),
        TemplateName: template == "" ? updateTemplateId : template,
        status: "Live",
        CreatedBy: 1,
        UpdatedBy: 1,
      };
      debugger;
      console.log(data);
      debugger;
      console.log("Channel :" + channel);
      const response = await axios.put(`${apiUrlAdvAcc}/UpdateCampaign`, data);

      if (response.data.status === "Success") {
        toast.success("The campaign details were updated successfully");
        setTimeout(() => {
          navigate("/navbar/campaignlist");
        }, 1000);
      } else {
        console.error("Upload failed:", response.data.Status_Description);
        toast.error("An error occurred while updating the campaign details");
        setTimeout(() => {
          /* wait for 1 second */
        }, 1000);
      }
    } catch (e) {
      console.error("Error in submitting form", e);
    }
  };

  debugger;
  const channelFilter = (channelTemplate: any) => {
    console.log("Channel : " + channelTemplate);

    const channelFilterId = channelList.find(
      (cid) => cid.channel_id === channelTemplate
    );
    const channelFilterName = channelFilterId
      ? channelFilterId.channel_name
      : "";

    const templatefilterlist1 = templateList.filter(
      (tname) => tname.channel_type === channelFilterName
    );
    setTemplatefilterlist(templatefilterlist1);
    debugger;

    console.log("Filter list : " + templatefilterlist);
  };

  // const getTemplateChannel = () => {
  //   const findchannel = templateList.find(
  //     (findtemplate) => findtemplate.template_id === parseInt(template)
  //   );
  //   const channelType = findchannel ? findchannel.channel_type : "";
  //   setChannel(channelType);
  // };

  // useEffect(() => {
  //   getTemplateChannel();
  // }, [template]);

  return (
    <>
      <div className="overflow-y-auto ml-[-7px]">
        <ToastContainer />

        <div className="fixed flex justify-end gap-4 mr-[40px] items-end right-[0px] top-[-15px] z-20 ">
          <Button
            variant={"outline"}
            className="w-[80px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
            onClick={() => {
              dispatch(setCreateBreadCrumb(false));
              navigate("/navbar/campaignlist")}}
          >
            Discard
          </Button>
          <Button
            className="w-[80px]"
            onClick={() => {
              if (campaignId) {
                handleEdit(); // Call handleEdit if campaignId exists
              } else {
                handleSubmit(); // Call handleSubmit if campaignId does not exist
              }
              console.log("Clicked"); // Log the click event
            }}
          >
            {(campaignId?'Update':'Submit' )}
            
          </Button>
        </div>
        <div className="gap-4 flex ">
          <div className="ml-4">
            <Card className="w-[580px] mt-2 p-4">
              <div className="text-left">
                <h3 className="text-md font-semibold text-left">
                  Create campaign
                </h3>
                <div className="mt-4">
                  <Label htmlFor="campaignName" className="mt-8">
                    Campaign name
                  </Label>
                  <Input
                    id="campaignName"
                    type="text"
                    placeholder="Enter your campaign name"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    className="mt-2 text-gray-500"
                  />
                  {campaignNameError && (
                    <p className="text-red-500 text-sm">{campaignNameError}</p>
                  )}
                </div>

                <div className="mt-4">
                  <Label htmlFor="channel" className="mt-2">
                    Channel
                  </Label>
                  <Select
                    value={channel}
                    onValueChange={(value) => {
                      channelFilter(value);
                      console.log("Selected Channel ID:", value);
                      setChannel(value);
                    }}
                  >
                    <SelectTrigger className="text-gray-500 mt-2">
                      <SelectValue
                        className="text-gray-500"
                        placeholder={
                          campaignId
                            ? updateChannel
                            : "Select your campaign channel"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {channelList.map((channel) => (
                        <SelectItem
                          className="text-gray-500"
                          key={channel.channel_id}
                          value={channel.channel_id as any}
                        >
                          {channel.channel_name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  {channelError && (
                    <p className="text-red-500 text-sm">{channelError}</p>
                  )}
                </div>

                <div className="mt-4">
                  <Label htmlFor="template" className="mt-2">
                    Template
                  </Label>
                  <Select
                    value={template}
                    onValueChange={(value) => {
                      console.log("Selected Template ID:", value);
                      setTemplate(value);
                    }} // Use onValueChange instead of onChange
                  >
                    <SelectTrigger className="text-gray-500 mt-2">
                      <SelectValue
                        className="text-gray-500"
                        placeholder={
                          campaignId
                            ? updateTemplate
                            : "Select your template to send"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {templatefilterlist.map((template) => (
                        <>
                          {/* {setChannel(template.channel_type)} */}
                          <SelectItem
                            className="text-gray-500"
                            key={template.template_id}
                            value={template.template_id as any}
                          >
                            {" "}
                            {/* Adjust according to your country object structure */}
                            {template.template_name}{" "}
                            {/* Assuming country object has a 'name' property */}
                          </SelectItem>
                        </>
                      ))}
                    </SelectContent>
                  </Select>
                  {templateError && (
                    <p className="text-red-500 text-sm">{templateError}</p>
                  )}
                </div>

                {/* <div className="mt-4">
                  <Label htmlFor="channel" className="mt-2">
                    Channel
                  </Label>
                  <Input
                    type="text"
                    value={channel ? channel : updateChannel}
                    readOnly
                    className="text-gray-500 mt-2 w-full border border-gray-300 rounded p-2"
                  />
                  {channelError && (
                    <p className="text-red-500 text-sm">{channelError}</p>
                  )}
                </div> */}
              </div>
            </Card>

            <Card className="w-[580px] mt-6 p-4">
              <div className="mt-2 text-left">
                <h3 className="text-md font-semibold text-left">
                  Target audience
                </h3>
                <p className="mt-2 text-sm font-medium text-gray-500">
                  Our ad technology automatically finds your audience.
                </p>
                <div className="mt-4">
                  <Label htmlFor="reachPeopleFrom" className="mt-2">
                    Reach people from
                  </Label>
                  <Select
                    value={reachPeopleFrom}
                    onValueChange={(value) => {
                      console.log("Selected Country ID:", value); // Log the selected value
                      setReachPeopleFrom(value); // Update state with the selected value
                    }}
                  >
                    <SelectTrigger className="text-gray-500 mt-2">
                      <SelectValue
                        className="text-gray-500"
                        placeholder={
                          campaignId ? updateCountry : "Select Country"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {countryList.map((country) => (
                        <SelectItem
                          className="text-gray-500"
                          key={country.country_id}
                          value={country.country_id as any}
                        >
                          {" "}
                          {/* Adjust according to your country object structure */}
                          {country.country_name}{" "}
                          {/* Assuming country object has a 'name' property */}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {targetCountryError && (
                    <p className="text-red-500 text-sm">{targetCountryError}</p>
                  )}
                </div>
                <div className="mt-4">
                  <Label htmlFor="reachPeopleIn" className="mt-2">
                    Reach people in
                  </Label>
                  <Select
                    value={reachPeopleIn}
                    onValueChange={(value) => {
                      console.log("Selected Country ID:", value); // Log the selected value
                      setReachPeopleIn(value); // Update state with the selected value
                    }}
                  >
                    <SelectTrigger className="text-gray-500 mt-2">
                      <SelectValue
                        className="text-gray-500"
                        placeholder={
                          campaignId ? updateRoamingCountry : "Select Country"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {countryList.map((country) => (
                        <SelectItem
                          className="text-gray-500"
                          key={country.country_id}
                          value={country.country_id as any}
                        >
                          {" "}
                          {/* Adjust according to your country object structure */}
                          {country.country_name}{" "}
                          {/* Assuming country object has a 'name' property */}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {roamingCountryError && (
                    <p className="text-red-500 text-sm">
                      {roamingCountryError}
                    </p>
                  )}
                </div>
              </div>
            </Card>

            <Card className="w-[580px] mt-6 mb-[100px] p-4">
              <div className="mt-2 text-left">
                <h3 className="text-md font-semibold text-left">
                  Budget & schedule
                </h3>
                <div className="mt-4">
                  <Label htmlFor="campaignBudget" className="mt-2 ">
                    Campaign budget
                  </Label>
                  <div className="flex gap-2 mt-1">
                    <div className="relative w-full">
                      <Input
                        id="campaignBudget"
                        type="number"
                        value={campaignBudget}
                        className="text-gray-500 mt-2 pr-10" // Add padding to the right for the "USD" text
                        onChange={(e) => setCampaignBudget(e.target.value)}
                      />
                      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        USD
                      </span>
                    </div>
                  </div>

                  {budgetError && (
                    <p className="text-red-500 text-sm">{budgetError}</p>
                  )}
                </div>
                {/* <div className="mt-4">
                  <Label htmlFor="campaignStartDate" className="mt-2">
                    Campaign start date
                  </Label>
                  <Input
                    id="campaignStartDate"
                    type="date"
                    value={campaignStartDate}
                    onChange={(e) => setCampaignStartDate(e.target.value)}
                    className="mt-2 text-gray-500"
                    
                  />
                  {startdateError && (
                  <p className="text-red-500 text-sm">{startdateError}</p>
                )}
                </div>
                <div className="mt-4">
                  <Label htmlFor="campaignEndDate" className="mt-2">
                    Campaign end date
                  </Label>
                  <Input
                    id="campaignEndDate"
                    type="date"
                    value={campaignEndDate}
                    onChange={(e) => setCampaignEndDate(e.target.value)}
                    className="mt-2 text-gray-500"
                  />
                  {enddateError && (
                  <p className="text-red-500 text-sm">{enddateError}</p>
                )}
                </div> */}

                <div className="mt-4">
                  <Label htmlFor="campaignStartDate" className="mt-2">
                    Campaign start date
                  </Label>
                  <div className="relative mt-2 text-gray-500">
                    <input
                      id="campaignStartDate"
                      type="text"
                      value={campaignStartDate}
                      onChange={(e) => setCampaignStartDate(e.target.value)}
                      placeholder="dd/mm/yyyy"
                      style={{ fontSize: "14px" }}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <Popover
                      open={isStartCalendarOpen}
                      onOpenChange={setStartCalendarOpen}
                    >
                      <PopoverTrigger asChild>
                        <button
                          className="absolute right-2 top-1/2 transform -translate-y-1/2"
                          onClick={() =>
                            setStartCalendarOpen(!isStartCalendarOpen)
                          }
                        >
                          <CalendarIcon className="text-gray-500" />
                        </button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <Calendar
                          mode="single"
                          selected={
                            campaignStartDate
                              ? new Date(
                                  campaignStartDate
                                    .split("/")
                                    .reverse()
                                    .join("-")
                                )
                              : undefined
                          }
                          onSelect={handleStartDateChange}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                  {startdateError && (
                    <p className="text-red-500 text-sm">{startdateError}</p>
                  )}
                </div>

                <div className="mt-4">
                  <Label htmlFor="campaignEndDate" className="mt-2">
                    Campaign end date
                  </Label>
                  <div className="relative mt-2 text-gray-500">
                    <input
                      id="campaignEndDate"
                      type="text"
                      value={campaignEndDate}
                      onChange={(e) => setCampaignEndDate(e.target.value)}
                      placeholder="dd/mm/yyyy"
                      style={{ fontSize: "14px" }}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <Popover
                      open={isEndCalendarOpen}
                      onOpenChange={setEndCalendarOpen}
                    >
                      <PopoverTrigger asChild>
                        <button
                          className="absolute right-2 top-1/2 transform -translate-y-1/2"
                          onClick={() => setEndCalendarOpen(!isEndCalendarOpen)}
                        >
                          <CalendarIcon className="text-gray-500" />
                        </button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <Calendar
                          mode="single"
                          selected={
                            campaignEndDate
                              ? new Date(
                                  campaignEndDate.split("/").reverse().join("-")
                                )
                              : undefined
                          }
                          onSelect={handleEndDateChange}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                  {enddateError && (
                    <p className="text-red-500 text-sm">{enddateError}</p>
                  )}
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-2  mb-8">
            <Card className="w-[430px] p-4">
              <h3 className="text-md font-semibold text-left">Audience size</h3>
              <div className="mt-6 flex justify-center items-center">
                <div className="w-[150px] h-[150px] ">
                  <CircularProgressbar
                    value={percentage}
                    text={`${selectedRecipients}`}
                    styles={buildStyles({
                      textSize: "22px",
                      pathColor: "#007bff",
                      textColor: "#333",
                      trailColor: "#f0f0f0",
                    })}
                    className="whitespace-pre-line"
                  />
                </div>
              </div>

              <div className="mt-4 ">
                <div>
                  <span>
                    <p className="font-lg text-gray-800  ">
                      {selectedRecipients} out of {totalRecipients} total
                      recipients
                    </p>
                  </span>
                </div>
                <div className="flex items-start justify-center mt-2 space-x-2 pt-2  ">
                  {/* Icon aligned with the paragraph */}

                  <FontAwesomeIcon
                    className="text-gray-500"
                    icon={faArrowTrendUp}
                  />
                  <div>
                    <p className="text-[13px] w-[300px] font-medium text-gray-500 text-left">
                      The accuracy of estimates is based on factors such as past
                      campaign data, the budget you entered, market data,
                      targeting criteria, and channel numbers are provided to
                      give you an idea of performance for your budget, but are
                      only estimates and don't guarantee results.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
