import React, {
  FC,
  useState,
  useRef,
  ChangeEvent,
  FormEvent,
  useEffect,
} from "react";
import "./CSS/LoginPage.css";
import LoginImage from "./../Assets/LoginBackground.png";
import loginVideo from "../Assets/loginVideo.mp4";
import Logo from "../Assets/Logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Typography, Box, Switch } from "@mui/material";
import { Input } from "../Components/ui/input";
import { Button } from "../Components/ui/button";
import { Label } from "../Components/ui/label";
import "../Styles/globals.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../config.json";
import { CustomWorkspaceControl } from "./Workspace";
import { useDispatch } from "react-redux";
import { setPersonalData } from "../State/slices/AuthenticationSlice";

interface CustomLoginControlProps {
  setNext: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomLoginControl: FC<CustomLoginControlProps> = ({ setNext }) => {
  const [PageLoad, setPageLoad] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const location = useLocation();
  const email = location.state?.email || "";

  const [subscribe, setSubscribe] = useState<boolean>(false);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [base64Image, setBase64Image] = useState<string>("");

  const [fileError, setFileError] = useState("");

  const [LoginState, SetLoginState] = React.useState<{
    UserName: string;
    Password: string;
  }>({ UserName: "Arul", Password: "arul@123" });
  const navigate = useNavigate();

  const [apiUrl, setApiUrl] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((config) => {
        setApiUrl(config.API_URL);
      })
      .catch((error) => console.error("Error loading config:", error));
  }, []);

  const validateName = (value: string): boolean => {
    const regex = /^[a-zA-Z]*$/;
    return regex.test(value);
  };

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (validateName(value)) {
      setFirstName(value);
      setFirstNameError(null);
    } else {
      setFirstNameError("First name must only contain letters");
    }
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (validateName(value)) {
      setLastName(value);
    } else {
      setLastNameError("Last name must only contain letters");
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/png"];
      const maxFileSize = 5 * 1024 * 1024; // 5MB
      if (!validFileTypes.includes(file.type)) {
        setFileError("Please select a valid image (PNG or JPEG).");
        setFileName("");
      } else if (file.size > maxFileSize) {
        setFileError("File size should not exceed 5MB.");
        setFileName("");
      } else {
        setFileError("");
        setFileName(file.name);

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result?.toString().split(",")[1];
          setBase64Image(base64String || "");
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFileError("Please select an image");
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = {
        firstName,
        email,
        lastName,
        emailSubscription: subscribe ? "Yes" : "No",
        alternateNumber: null,
        city: "Your City",
        country: 0,
        address: "Your Address",
        languagePreference: 0,
        gender: "Your Gender",
        dateOfBirth: new Date().toISOString(),
        status: "Active",
        createdBy: 1,
        createdDate: new Date().toISOString(),
        updatedBy: 1,
        updatedDate: new Date().toISOString(),
        mappingId: 0,
        base64Image,
      };
      dispatch(setPersonalData(data));
      toast.success("The Personal details saved successfully ", {
            autoClose: 1000,
            onClose: () => {
              setNext(true);
            },
          });
      // const response = await axios.post(
      //   `${apiUrl}/InsertUserPersonalInfo`,
      //   data
      // );
      // if (response.data.status === "Success") {
      //   toast.success("The Personal details saved successfully ", {
      //     autoClose: 1000,
      //     onClose: () => {
      //       setNext(true);
      //     },
      //   });
      // } else {
      //   toast.error(response.data.Status_Description, {
      //     autoClose: 1000,
      //   });
      // }
    } 
    catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Container sx={{ width: "400px", padding: "0.5rem" }}>
      <ToastContainer />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          fontSize: "0.85rem",
        }}
      >
        <Label
          htmlFor="pageprogress"
          className="whitespace-nowrap text-left"
          style={{ fontSize: "14px", color: "#71717A" , fontWeight: 400 }}
        >
          1/2
        </Label>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ textAlign: "left", fontWeight: 600, fontSize: "24px", color: "#09090B", marginBottom: 0 }}
        >
          Let us get to know you
        </Typography>

        <Label
          htmlFor="profilePicture"
          className="whitespace-nowrap text-left"
          style={{ fontSize: "14px", marginTop: "2rem" , color: "#020617" }}
        >
          Profile picture
        </Label>
        <div className="flex w-full  gap-2">
          <Input
            type="text"
            value={fileName}
            readOnly
            className="flex-grow w-[80%] border-[#E2E8F0] placeholder-[#64748B]"
            placeholder="Choose File   No file chosen"
            style={{ fontWeight: 500 }}
            
          />
          <Button
            type="button"
            onClick={handleUploadClick}
            className="bg-[#F1F5F9] w-[30%] text-gray-700 hover:bg-gray-300 focus:ring-gray-400  mt--1"
            style={{
              height: "2.2rem",
              padding: "0 0.5rem",
              fontSize: "0.8rem",
            }}
          >
            Upload image
          </Button>
        </div>
        <input
          ref={fileInputRef}
          id="profilePicture"
          type="file"
          accept="image/*"
          className="sr-only"
          onChange={handleFileChange}
        />
        <p
          className="text-sm"
          style={{ fontSize: "13px", marginTop: "0.2rem" , color:"#64748B" }}
        >
          * .png, * .jpeg files up to 5MB at least 400px by 400px
        </p>

        <Label
          htmlFor="firstName"
          className="whitespace-nowrap text-left"
          style={{ fontSize: "14px", marginTop: "0.9rem", color: "#020617" }}
        >
          First name
        </Label>
        <Input
          required
          id="firstName"
          placeholder="Enter your first name.."
          value={firstName}
          className="w-full flex-grow  border-gray-200 placeholder:font-normal placeholder:text-[#64748B]"
          onChange={handleFirstNameChange}
        />

            


        <Label
          htmlFor="lastName"
          className="whitespace-nowrap text-left"
          style={{ fontSize: "0.8rem", marginTop: "0.9rem", color: "#020617" }}
        >
          Last name
        </Label>
        <Input
          required
          id="lastName"
          placeholder="Enter your last name.."
          value={lastName}
          className="w-full flex-grow  border-gray-200 placeholder:font-normal placeholder:text-[#64748B]"
          onChange={handleLastNameChange}
          onInvalid={(e: any) =>
            e.target.setCustomValidity("Please fill out your last name.")
          }
        />

        <Label
          htmlFor="email"
          className="whitespace-nowrap text-left"
          style={{ fontSize: "0.8rem", marginTop: "0.9rem" }}
        >
          Email
        </Label>
        <Input
          required
          disabled
          type="email"
          id="email"
          value={email}
          placeholder="sebastian@nike.com"
          className="w-full flex-grow  border-gray-200"
          style={{ fontSize: "14px", color: "#020617" , backgroundColor: "#E2E8F0"}}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "left",
                fontWeight: 500,
                fontSize: "14px",
                color: "#020617",
                marginBottom: "0.1rem"
              }}
            >
              Subscribe to product updates
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "left",fontSize: "14px",  // Set font-size to 14px
                color: "#64748B", }}
            >
              Get the latest updates about features <br></br>and product
              updates.
            </Typography>
          </Box>
          <Switch
            checked={subscribe}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSubscribe(e.target.checked)
            }
            color="primary"
            sx={{
              "& .MuiSwitch-track": {
                backgroundColor: "#E2E8F0",               // Light gray track color
                opacity: 1,
                borderRadius: 20 / 2,
                boxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.1)",  // Soft inner shadow for depth
              },
              "& .MuiSwitch-thumb": {
                color: "#FFFFFF",                        // White color for the thumb
                width: 20,                               // Slightly larger for more presence
                height: 20,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",   // Soft shadow around the thumb
              },
              "&.Mui-checked .MuiSwitch-thumb": {
                color: "#FFFFFF",
                transform: "translateX(16px)",           // Shift thumb when checked
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Same shadow for checked state
              },
              "&.Mui-checked .MuiSwitch-track": {
                backgroundColor: "#CBD5E1",              // Slightly darker gray when checked
              }
            }}
          />
        </Box>

        <Button
          type="submit"
          className="w-full mt-2 bg-blue-600"
          style={{
            padding: "0.4rem",
            height: "2rem",
            backgroundColor: "#007AFF", 
            fontSize: "14px", 
            color: "#FAFAFA", 
            fontWeight: 500,
          }}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

const PersonalInfo: FC<{
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setAuthenticated }) => {
  const [next, setNext] = React.useState<boolean>(false);

  return (
    <div
      className="personal-info-page"
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "30px",
          left: "30px",
          zIndex: 20,
        }}
      >
        <img src={Logo} alt="Logo" style={{ width: "170px", height: "auto" }} />
      </div>

      <div
        className="left-pane"
        style={{
          flex: '1.5',
          // backgroundImage: `url(${figmaPageImage})`,
          // backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          position: 'relative',
          overflow: 'hidden'
        }}
      
      >
         <video autoPlay loop muted playsInline className="background-video"
         style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'fill'}}>
          <source src={loginVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
       </div>
      <div
        className="right-pane"
        style={{
          flex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: "400px",
            maxWidth: "100%",
          }}
        >
          {!next ? (
            <CustomLoginControl setNext={setNext} />
          ) : (
            <CustomWorkspaceControl
              setNext={setNext}
              setAuthenticated={setAuthenticated}
            />
          )}
        </Box>
      </div>
    </div>
  );
};

export default PersonalInfo;
