import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface SignupData {
    email: string;
    phoneNumber: string;
    emailVerified: string;
    phoneVerified: string;
    password: string;
}

interface PersonalInfo {
    firstName: string;
    email: string;
    lastName: string;
    emailSubscription: string;
    alternateNumber: string | null;
    city: string;
    country: number;
    address: string;
    languagePreference: number;
    gender: string;
    dateOfBirth: string;
    status: string;
    createdBy: number;
    createdDate: string;
    updatedBy: number;
    updatedDate: string;
    mappingId: number;
    base64Image: string;
}

interface WorkspaceInfo {
    email: string;
    workspaceName: string;
    billingCountry: string;
    workspaceIndustry: string;
    workspaceType: string;
    status: string;
    createdBy: number;
    createdDate: string;
    updatedBy: number;
    updatedDate: string;
    mappingId: number;
    base64Image: string;
}

interface LoginState{
    workspaceName:string,
    userEmail:string,
    isAuthenticated:boolean,
    count:number,
    workspace_id:number,
    userData?: PersonalInfo,
    workspaceData?: WorkspaceInfo,
    signupData?: SignupData;
    apiURL: string;
}


const initialState: LoginState = {
    workspaceName:"Admin",
    userEmail:"",
    isAuthenticated:false,
    count:0,
    workspace_id: 86,
    userData: undefined,
    workspaceData: undefined,
    signupData: undefined,
    apiURL: "",

}

const AuthenticationSlice = createSlice({
    name: "Authentication",
    initialState: initialState,
    reducers:{

        // increment:(state,action: PayloadAction<number>)=>{

        //     state.count+=action.payload;
        // },



        setmail:(state,action: PayloadAction<string>)=>{
            state.userEmail=action.payload;
        },

        setWorkspaceId:(state,action: PayloadAction<number>)=>{
            state.workspace_id = action.payload;
        },

        setworkspace:(state,action:PayloadAction<string>)=>{
            state.workspaceName=action.payload;
        },

        setPersonalData: (state, action: PayloadAction<PersonalInfo>) => {
            state.userData = action.payload;
        },

        setWorkspaceData: (state, action: PayloadAction<WorkspaceInfo>) => {
            state.workspaceData = action.payload;
        },

        setSignupData: (state, action: PayloadAction<SignupData>) => {
            state.signupData = action.payload;
        },

        setAdvUrl:(state, action: PayloadAction<string>)=>{
            state.apiURL = action.payload;
        },

    },
})

export const {setmail,setworkspace,setPersonalData,setSignupData,setWorkspaceData,setWorkspaceId,setAdvUrl} = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;