import React, { FC, useEffect, useState } from "react";
import { Progress } from "../ui/progress";
import {
  User,
  Bell,
  Briefcase,
  CreditCard,
  Users,
  ChevronLeft,
} from "lucide-react";
import { DropdownMenuDemo } from "./Dropdown";
import { BellIcon } from "@radix-ui/react-icons";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../ui/resizable";
import { cn } from "../../lib/utils";
import { Separator } from "../ui/separator";
import { Tabs, TabsContent } from "../ui/tabs";
import { TooltipProvider } from "../ui/tooltip";
import { Link, Outlet } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "../ui/breadcrumb";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/src/State/store";

// Define the menu items with routes as content
const menuItems = [
  { label: "Profile", icon: User, path: "Profile" },
  { label: "Notifications", icon: Bell, path: "Notification" },
  { label: "Workspace", icon: Briefcase, path: "Workspace_settings" },
  { label: "Members", icon: Users, path: "Members" },
  { label: "Billing", icon: CreditCard, path: "Billing" },
];

const NavItem = ({
  icon: Icon,
  label,
  path,
  onClick,
  select,
}: {
  icon: React.ElementType;
  label: string;
  path: string;
  onClick: () => void;
  select: string;
}) => (
  <Link
    to={path}
    onClick={onClick}
    className={
      select === label
        ? "w-full text-left flex items-center space-x-3 text-black bg-gray-100 rounded-lg px-3 py-2"
        : "w-full text-left flex items-center space-x-3 text-gray-500 hover:text-black hover:rounded hover:bg-gray-100 rounded-lg px-3 py-2"
    }
  >
    <Icon className="h-5 w-5" />
    <span className="text-[14px]">{label}</span>
  </Link>
);

const NavLinks: FC<{ onSelect: (label: string) => void; selected: string }> = ({
  onSelect,
  selected,
}) => (
  <div className="h-full bg-[#FBFBFB]">
  <nav className="w-[calc(100%-20px)] ml-[10px] h-full flex flex-col bg-[#FBFBFB]">
    <div className="flex-1 overflow-y-auto py-4">
      {menuItems.map((item, index) => (
        <NavItem
          key={index}
          icon={item.icon}
          label={item.label}
          path={item.path}
          onClick={() => onSelect(item.label)}
          select={selected}
        />
      ))}
    </div>
    <div className="sticky bottom-0 p-4 bg-white w-full mb-[80px]">
      <div className="flex flex-col gap-1">
        <span className="text-sm text-gray-500 text-left">
          7,328/10,000 Messages
        </span>
        <Progress value={73} className="" />
      </div>
    </div>
  </nav>
  </div>
);

const Layout: FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("Profile"); // Default label
  const [workspaceName, setWorkspaceName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // const workspace = location.state?.path || "Admin";
  const workspace = useSelector((state: RootState) => state.authentication.workspaceName);
  const email = useSelector((state: RootState) => state.authentication.userEmail);
  useEffect(() => {
    if (workspace !== "") {
      setWorkspaceName(workspace);
    }
  }, [workspace]);

  return (
    <div className="h-screen">
      <TooltipProvider delayDuration={0}>
        <ResizablePanelGroup
          direction="horizontal"
          onLayout={(sizes: number[]) => {
            document.cookie = `react-resizable-panels:layout:mail=${JSON.stringify(
              sizes
            )}`;
          }}
          className="h-full items-stretch"
        >
          <ResizablePanel
            collapsible={true}
            minSize={15}
            maxSize={20}
            onCollapse={() => {
              setIsCollapsed(true);
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                true
              )}`;
            }}
            onResize={() => {
              setIsCollapsed(false);
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                false
              )}`;
            }}
            className={cn(
              isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out"
            )}
          >
            <div
              className={cn("flex h-[60px] items-center justify-center bg-[#FBFBFB]", "px-2")}
            >
              <span
                className="flex w-full text-md justify-start items-center gap-2 border-transparent p-2 font-bold cursor-pointer"
                onClick={() =>
                  navigate("/navbar/dashboard", {
                    state: { path: workspaceName },
                  })
                }
              >
                <ChevronLeft size={20} className="ml-[-2px] mr-[1px]" />
                <div>Settings</div>
              </span>
            </div>

            <Separator />
            <NavLinks onSelect={setSelectedLabel} selected={selectedLabel} />
          </ResizablePanel>

          <ResizableHandle />

          <ResizablePanel minSize={30} className="h-full">
            <Tabs defaultValue="all" className="h-full">
              <div className="flex w-full">
                <div className="flex-col items-center px-4 py-2 h-[60px]">
                  <div>
                    <Breadcrumb>
                      <BreadcrumbList>
                        <BreadcrumbItem>
                          <BreadcrumbLink className="text-[8px]">
                            <Link to="/">{workspaceName}</Link>
                          </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                          <BreadcrumbLink className="text-[8px]">
                            <Link to="/settings">Settings</Link>
                          </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem className="text-[8px]">
                          <BreadcrumbPage>{selectedLabel}</BreadcrumbPage>
                        </BreadcrumbItem>
                      </BreadcrumbList>
                    </Breadcrumb>
                  </div>
                  <h1 className="text-xl text-left font-bold">{selectedLabel}</h1>
                </div>
              </div>
              <Separator />
              <TabsContent value="all" className="h-full overflow-y-auto m-0">
                <div className="h-full p-4">
                  <Outlet />
                </div>
              </TabsContent>
            </Tabs>
          </ResizablePanel>
        </ResizablePanelGroup>
      </TooltipProvider>
    </div>
  );
};

export default Layout;
