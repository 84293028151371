import React, { useState, useEffect } from "react";
import { Button } from "../../Components/ui/button";
import { Input } from "../../Components/ui/input";
import { cn } from "../../lib/utils";
import { Badge } from "../../Components/ui/badge";
import { Link } from "react-router-dom";
import {
  DotsHorizontalIcon,
  CalendarIcon,
  FileIcon,
  CaretSortIcon,
  ArrowRightIcon,
} from "@radix-ui/react-icons";
import { FiFilter } from "react-icons/fi";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/ui/table";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "../../Components/ui/breadcrumb";
import { Calendar } from "../../Components/ui/calendar";
import { Separator } from "@radix-ui/react-select";
import axios from "axios";
import { Tabs, TabsContent } from "../../Components/ui/tabs";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../Components/ui/dropdown-menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import config from "../../config.json";
import {
  PlayIcon,
  PauseIcon,
  StopwatchIcon,
  MagnifyingGlassIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { CircularProgress } from "@mui/material";
import { Skeleton } from "../../Components/ui/skeleton";
import DropdownMenuDemo from "../../Components/Filter/CampaignDropdown";
import { useDispatch } from "react-redux";
import { setCreateBreadCrumb } from "../../State/slices/AdvertiserAccountSlice";

interface Campaign {
  campaign_id: number;
  campaign_name: string;
  channel_type: string;
  status: string;
  start_date_time: string;
  end_date_time: string;
  campaign_budget: number;
  sent: string;
}

type CampaignCheck = {
  campaign_id: number;
  name: string;
};

interface DatePickerWithRangeProps {
  className?: string;
}

interface StatusCellProps {
  status: "Live" | "Pending" | "Paused" | "In review" | "Completed"; // Limit status to specific strings
}

const DatePickerWithRange: React.FC<DatePickerWithRangeProps> = ({
  className,
}) => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(),
    to: addDays(new Date(), 7),
  });

  return (
    <div className={className}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-[254px] justify-start text-left font-normal mt-0",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

const renderStatusIcon = (status: any) => {
  switch (status) {
    case "Live":
      return <PlayIcon className="text-gray-500" />; // Play icon for 'Live'
    case "Pending":
      return <StopwatchIcon className="text-gray-500" />; // Stopwatch icon for 'Pending'
    case "Paused":
      return <PauseIcon className="text-gray-500" />; // Pause icon for 'Paused'
    case "In review":
      return <MagnifyingGlassIcon className="text-gray-500" />; // Magnifying glass icon for 'In review'
    case "Completed":
      return (
        <CheckIcon className="text-gray-500 rounded-full border border-gray-500" />
      ); // Check icon for 'Completed'
    default:
      return null; // If no match, return nothing
  }
};

const CampaignList: React.FC = () => {
  const [openMenuRowId, setOpenMenuRowId] = useState<number | null>(null);
  const [campaignList, setCampaignList] = useState<Campaign[]>([]);
  const navigate = useNavigate();
  const [isAlertOpen, setIsAlertOpen] = useState(false); // State to control dialog visibility
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [currentCampaigns, setCurrentCampaigns] = useState<Campaign[]>([]);

  const [isSorted, setIsSorted] = useState(false);
  const [originalCampaigns, setOriginalCampaigns] = useState(currentCampaigns);

  const [checkboxSelectedRows, setCheckboxSelectedRows] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // Default 5 rows per page
  const [searchTerm, setSearchTerm] = useState("");
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    filter: "",
    subFilter: "",
    value: 0,
  });
  const [hasCampaigns, setHasCampaigns] = useState(false);
  const dispatch = useDispatch();

  

  const handleCheckboxRowSelect = (id: number) => {
    setCheckboxSelectedRows((prev) => {
      const newSelectedRows = prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id];
      setIsAllSelected(newSelectedRows.length === currentCampaigns.length); // Update `isAllSelected` if all rows are selected
      return newSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setCheckboxSelectedRows([]);
    } else {
      const allIds = currentCampaigns.map((campaign) => campaign.campaign_id);
      setCheckboxSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setApiUrlAdvAcc(config.ApiUrlAdvAcc);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (apiUrlAdvAcc) {
      getCampaignList();
    }
  }, [apiUrlAdvAcc]); // Runs when apiUrlAdvAcc is updated

  // Filter the campaigns by the search term and subFilter
  const filteredCampaigns = campaignList.filter((campaign) => {
    const matchesSearchTerm = searchTerm
      ? campaign.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    const matchesSubFilter = filterData.subFilter
      ? campaign.channel_type
          .toLowerCase()
          .includes(filterData.subFilter.toLowerCase()) ||
        campaign.status
          .toLowerCase()
          .includes(filterData.subFilter.toLowerCase())
      : true;

    return matchesSearchTerm && matchesSubFilter;
  });

  // Calculate total pages for filtered campaigns
  const totalPages: number = Math.ceil(filteredCampaigns.length / rowsPerPage);

  useEffect(() => {
    const newCurrentCampaigns = filteredCampaigns.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    setCurrentCampaigns(newCurrentCampaigns);
  }, [filterData, campaignList, currentPage, rowsPerPage, searchTerm]);
  // Only re-run if dependencies change

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleEdit = (campaignId: any, channelType: any) => {
    //console.log("CampaignId : " + campaignId)
    navigate("/navbar/createCampaign", { state: { campaignId, channelType } });
  };

  // Function to open the alert dialog
  const handleDeleteClick = (campaignId: any) => {
    setCampaignToDelete(campaignId); // Store campaign ID to delete
    setIsAlertOpen(true); // Open the alert dialog
  };

  const handleClose = () => {
    setIsAlertOpen(false);
  };

  // Function to handle the actual delete after confirmation

  useEffect(() => {
    console.log(
      "filter data: " +
        filterData.filter +
        " " +
        filterData.subFilter +
        " " +
        filterData.value
    );
  }, [filterData]);

  // Use useEffect to avoid re-render loop
  useEffect(() => {
    if (filterData.filter === "none") {
      setFilterData({ filter: "none", subFilter: "", value: 0 });
    }
  }, [filterData.filter]);

  const getCampaignList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlAdvAcc}/GetCampaignList`);
      // Assuming the response data contains a 'CountryList' field as discussed earlier
      if (response.data && response.data.campaignList) {
        setCampaignList(response.data.campaignList);
        setIsLoading(false);
        console.log("Campaign List : ", response.data.campaignList);
      } else {
        console.log("No campaign list available in response.");
        setIsLoading(false);
      }
    } catch (error) {
      // Handle error if API call fails

      console.error("Error fetching campaign list:", error);
    } finally {
      // Ensure the menu is closed after fetching data
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${apiUrlAdvAcc}/DeleteCampaignById?CampaignId=` + campaignToDelete
      );

      if (response.data.status === "Success") {
        setIsAlertOpen(false);
        toast.success("The campaign was deleted successfully", {
          autoClose: 1000,
          onClose: () => {
            getCampaignList();
          },
        });

        // Refresh the campaign list
      } else {
        console.error("Delete failed:", response.data.Status_Description);
        toast.error("Error while deleting the campaign");
        setTimeout(() => {
          /* wait for 1 second */
        }, 1000);
      }
    } catch (e) {
      console.error("Error in deleting campaign", e);
    }
  };

  const handleMenuToggle = (rowId: number) => {
    setOpenMenuRowId(openMenuRowId === rowId ? null : rowId);
  };

  const sortCampaignList = (tableHeader: string) => {
    const sortByField = (
      field: keyof (typeof currentCampaigns)[0],
      type: "string" | "number" | "date" = "string"
    ) => {
      const sortedCampaigns = [...currentCampaigns].sort((a, b) => {
        if (type === "number") {
          return Number(a[field]) - Number(b[field]);
        } else if (type === "date") {
          return (
            Date.parse(a[field] as string) - Date.parse(b[field] as string)
          );
        } else {
          return String(a[field]).localeCompare(String(b[field]));
        }
      });
      setOriginalCampaigns(currentCampaigns);
      setCurrentCampaigns(sortedCampaigns);
    };

    if (isSorted) {
      setCurrentCampaigns(originalCampaigns);
    } else {
      switch (tableHeader) {
        case "ByCampaignName":
          sortByField("campaign_name", "string");
          break;
        case "ByCampaignChannel":
          sortByField("channel_type", "string");
          break;
        case "ByCampaignStatus":
          sortByField("status", "string");
          break;
        case "ByCampaignDate":
          sortByField("start_date_time", "date"); // Sorting by start date
          break;
        case "ByCampaignAmount":
          sortByField("campaign_budget", "number"); // Sorting by budget as a number
          break;
        case "ByCampaignSent":
          sortByField("sent", "number"); // Sorting by sent count as a number
          break;
        default:
          console.warn("Unknown table header");
      }
    }

    setIsSorted(!isSorted);
    console.log("Sorted campaigns:", currentCampaigns); // For debugging
  };

  // Function to handle checkbox selection
  // const handleRowSelect = (campaignId: CampaignId): void => {
  //   setSelectedRows((prevSelected) =>
  //     prevSelected.includes(campaignId)
  //       ? prevSelected.filter((id) => id !== campaignId)
  //       : [...prevSelected, campaignId]
  //   );
  // };

  useEffect(() => {
    setHasCampaigns(campaignList.length > 0);
  }, [campaignList]);
  // const hasCampaigns = campaignList.length > 0;

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div>
          <div className="flex  mt-4">
            <Skeleton className="w-[350px] h-10 mb-4" />{" "}
            {/* Skeleton for search input */}
            <div className="flex items-end ml-auto">
              <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "}
              {/* Skeleton for date picker */}
              <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "}
              {/* Skeleton for filter button */}
              <Skeleton className="w-32 h-10 mb-4 ml-4" />{" "}
              {/* Skeleton for export button */}
            </div>
          </div>

          {/* Table Skeleton */}
          <div className="rounded-md border">
            <Table className="rounded-xl border-gray-200">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <Skeleton className="w-full h-6" />{" "}
                    {/* Skeleton for table header */}
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                  <TableHead>
                    <Skeleton className="w-full h-6" />
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Array.from({ length: 5 }).map((_, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>{" "}
                    {/* Skeleton for table cell */}
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="w-full h-8" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
      <div className="fixed flex justify-end items-end right-0 top-[-15px] z-20 p-4">
        <Button
          onClick={() => {
            dispatch(setCreateBreadCrumb(true));
            navigate("/navbar/createcampaign");
          }}
          className="w-36 text-sm font-thin h-[35px] mt-[10px]"
        >
          Create Campaign
        </Button>
      </div>

      {hasCampaigns ? (
        <div>
          {/* Existing table code here */}
          <div className="flex  mt-2">
            <div className=" ">
              <Input
                placeholder="Search campaign by name..."
                className="w-[350px] text-[14px] font-normal text-[#64748B]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex items-end ml-auto ">
              <div className="mb-6">
                <DatePickerWithRange />
              </div>
              <DropdownMenuDemo setFilterData={setFilterData} />
              <Button variant="outline" className="w-24 mb-6 ml-4 mt-[-6] text-[#020617]">
                <FileIcon className="mr-2 text-[#020617]" /> Export
              </Button>
            </div>
          </div>

          <div className="rounded-md border">
            <Table
              className="rounded-xl whitespace-nowrap border-gray-200  "
              style={{ color: "#020202", fontSize: "15px" }}
            >
              <TableHeader className="text-center text-[14px] font-medium">
                <TableRow>
                  <TableHead className="">
                    <div className="flex items-center gap-6 justify-start cursor-pointer">
                      <input
                        type="checkbox"
                        className={`text-muted-foreground ${
                          isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                        }`}
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                      Campaign name{" "}
                      <CaretSortIcon
                        onClick={() => sortCampaignList("ByCampaignName")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>

                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Channel{" "}
                      <CaretSortIcon
                        onClick={() => sortCampaignList("ByCampaignChannel")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Status{" "}
                      <CaretSortIcon
                        onClick={() => sortCampaignList("ByCampaignStatus")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Schedule{" "}
                      <CaretSortIcon
                        onClick={() => sortCampaignList("ByCampaignDate")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Amount{" "}
                      <CaretSortIcon
                        onClick={() => sortCampaignList("ByCampaignAmount")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left">
                    <div className="flex items-center gap-2 justify-start">
                      Sent{" "}
                      <CaretSortIcon
                        onClick={() => sortCampaignList("ByCampaignSent")}
                        className="cursor-pointer"
                      />
                    </div>
                  </TableHead>
                  <TableHead className="text-left"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="text-left text-[14px] font-normal text-[#020617] ">
                {currentCampaigns.map((campaign) => {
                  let isSelected;
                  campaignList.map((campaings) => {
                      isSelected = checkboxSelectedRows.includes(
                      campaings.campaign_id
                    );
                  })

                  return (
                    <TableRow
                      key={campaign.campaign_id}
                      className={`${isSelected ? "bg-gray-200" : ""}`}
                    >
                      <TableCell className="flex justify-start py-4 text-green-900">
                        <div className="flex items-center gap-6">
                            <input
                              type="checkbox"
                              className={`accent-gray-700 bg-grey-700 text-red-500 ${
                                          isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                                          }`}
                              checked={checkboxSelectedRows.includes(campaign.campaign_id)}
                              onChange={() => handleCheckboxRowSelect(campaign.campaign_id)}
                            />
                          <span style={{ color: "#020202", fontSize: "15px" }}>
                            {campaign.campaign_name}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className="py-4 text-[#FFFFFF]">
                        <Badge
                          className={
                            campaign.channel_type === "WhatsApp"
                              ? ""
                              : campaign.channel_type === "SMS"
                              ? ""
                              : campaign.channel_type === "Push Notification"
                              ? ""
                              : campaign.channel_type === "Email"
                              ? "bg-blue-400"
                              : "bg-gray-400"
                          }
                          style={
                            campaign.channel_type === "WhatsApp"
                              ? { backgroundColor: "#479E98" }
                              : campaign.channel_type === "SMS"
                              ? { backgroundColor: "#DFA548" }
                              : campaign.channel_type === "Push Notification" ||
                                campaign.channel_type === "Push"
                              ? { backgroundColor: "#B87867" }
                              : campaign.channel_type === "RCS" ||
                                campaign.channel_type === "RCS messages"
                              ? { backgroundColor: "#61177E" }
                              : {}
                          }
                        >
                          {campaign.channel_type}
                        </Badge>
                      </TableCell>
                      <TableCell className="py-4 flex items-center gap-2">
                        {renderStatusIcon(campaign.status)}{" "}
                        {/* Display the appropriate icon */}
                        {campaign.status} {/* Display the status text */}
                      </TableCell>
                      <TableCell className="py-4">
                        <div className="flex items-center gap-2">
                          {new Date(
                            campaign.start_date_time
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                          })}
                          , {new Date(campaign.start_date_time).getFullYear()}
                          <ArrowRightIcon />
                          {new Date(campaign.end_date_time).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "short",
                            }
                          )}
                          , {new Date(campaign.end_date_time).getFullYear()}
                        </div>
                      </TableCell>
                      <TableCell className="py-4 px-4 py-4 px-4 text-center">
                        ${campaign.campaign_budget}
                      </TableCell>
                      <TableCell className="py-4 px-4 py-4 px-4 text-center">
                        {campaign.sent}
                      </TableCell>
                      <TableCell className="flex justify-left py-4">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <DotsHorizontalIcon
                              onClick={() =>
                                handleMenuToggle(campaign.campaign_id)
                              }
                              className="cursor-pointer w-6 h-6"
                            />
                          </DropdownMenuTrigger>
                          {openMenuRowId === campaign.campaign_id && (
                            <DropdownMenuContent
                              align="end"
                              className="w-24 bg-gray-200"
                            >
                              <DropdownMenuItem
                                onClick={() =>
                                  handleEdit(
                                    campaign.campaign_id,
                                    campaign.channel_type
                                  )
                                }
                              >
                                Edit
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() =>
                                  handleDeleteClick(campaign.campaign_id)
                                }
                              >
                                Delete
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          )}
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>

          <Dialog
            open={isAlertOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiPaper-root": { borderRadius: "10px" } }}
          >
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this campaign ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outline" className="w-24" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="w-24" onClick={confirmDelete} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {/* Pagination controls */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2 text-gray-500 text-sm ">
              <span>{`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                currentPage * rowsPerPage,
                campaignList.length
              )} of ${campaignList.length} row(s) selected`}</span>
            </div>
            <div className="flex items-center space-x-4 font-medium text-sm">
              <span>Rows per page:</span>
              <select
                className="ml-2 border border-gray-300 rounded px-2 py-1"
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page after changing rows per page
                }}
              >
                {[5, 10, 20].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                «
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                ‹
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                ›
              </button>
              <button
                className="border p-1 pr-2 pl-2 rounded text-gray-500 hover:bg-gray-200"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                »
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[500px]">
          <h2 className="text-xl font-semibold mb-2">
            Here you will see all your campaigns
          </h2>
          <p className="text-gray-500 mb-4">
            Click the button below to create your first campaign.
          </p>
          <Button className="w-[150px]">Create campaign</Button>
        </div>
      )}
    </div>
  );
};

export default CampaignList;
