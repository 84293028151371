import React, { FC, useEffect, useState } from "react";
import figmaPageImage from "../Assets/LoginBackground.png";
import loginVideo from "../Assets/loginVideo.mp4";
import Logo from '../Assets/Logo.svg';
import GoogleIcon from "@mui/icons-material/Google";
import { Link, useNavigate } from "react-router-dom";
import { Input } from '../Components/ui/input';
import { Button } from "../Components/ui/button";
import { Container, Typography, Box, Divider, CircularProgress } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from '../config.json';
import crypto from 'crypto-js';

interface CustomSignupFormProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setUserEmailId: React.Dispatch<React.SetStateAction<string>>;
}

const CustomSignupForm: FC<CustomSignupFormProps> = ({ email, setEmail, setAuthenticated, setUserEmailId }) => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [password, setPassword] = useState("");
  const [apiUrl, setApiUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(config => {
        setApiUrl(config.API_URL);
      })
      .catch(error => console.error('Error loading config:', error));
  }, []);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function hashPasword(pass: string): string {
    return crypto.SHA256(pass).toString();
  }

  const handleNext = async (e: React.FormEvent): Promise<void> => {
    const isLogin = "Login";
    setIsLoading(true);
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      setIsLoading(false);
      return;
    }
    setEmailError(null);

    if (password.trim() === "") {
      setPasswordError("Password cannot be empty");
      setIsLoading(false);
      return;
    }

    setPasswordError(null);

    try {
      const response = await axios.post(`${apiUrl}/UserLogin`, {
        email: email,
        phoneNumber: "string",
        emailVerified: "string",
        phoneVerified: "string",
        password: hashPasword(password),
      });

      if (response.data[0]?.Status === "Success") {
        const emailId = await axios.post(`${apiUrl}/GetUserAccountByEmail`, {
          email: email
        });
        if (emailId.data[0]?.Status === "Success") {
          localStorage.setItem("userid", emailId.data[0]?.User_Account_Id);
        } else {
          console.log("User account ID not found");
        }

        toast.success("OTP sent to email", {
          autoClose: 1000,
          onClose: async () => {
            setAuthenticated(true);
            setUserEmailId(email);
            setIsLoading(false);
            navigate('/otpverify', { state: { isLogin, email } });
          }
        });
      } else {
        const errorMessage = response.data[0]?.Status_Description;
        if (errorMessage === "Password verification failed.") {
          toast.error("Incorrect password. Please try again.", {
            autoClose: 1000,
            onClose: () => {
              setIsLoading(false);
            }
          });
        } else if (errorMessage === "Email does not exist.") {
          toast.error("Email not found. Please create an account first.", {
            autoClose: 1000,
            onClose: () => {
              setIsLoading(false);
            }
          });
        } else {
          toast.error("Please create an account first", {
            autoClose: 1000,
            onClose: () => {
              setIsLoading(false);
            }
          });
        }
      }
    } catch (error) {
      console.error('Error during API call:', error);
      toast.error("An error occurred, please try again");
      setIsLoading(false);
    }  
  };

  return (
    <Container maxWidth="xs" style={{ padding: '16px', width: '400px' }}>
      <ToastContainer />
      {isLoading && (
        <div className="loading-overlay">
          <CircularProgress color="primary" />
        </div>
      )}
      <Box component="form" onSubmit={handleNext} style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4" gutterBottom style={{ textAlign: 'center', fontWeight: 600, fontSize: '24px', color: '#09090B', marginBottom: 0 }}>
          Log in to TravelAd
        </Typography>
        <Typography variant="body1" gutterBottom style={{ textAlign: 'center', color: '#71717A', fontSize: '14px', paddingTop: '8px' }}>
          Enter your email below to access your account
        </Typography>
        <Box className='space-y-2 mt-6'>
          <Input
            required
            type="email"
            placeholder="name@example.com"
            className="w-[350px] h-[36px] border-[#E4E4E7] custom-placeholder rounded-[7px]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography color="error" variant="body2" style={{ textAlign: 'center' }}>
            {emailError}
          </Typography>

          <Input
            required
            type="password"
            placeholder="Password"
            className="w-[350px] h-[36px] border-[#E4E4E7] custom-placeholder rounded-[7px]"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Typography color="error" variant="body2" style={{ textAlign: 'center' }}>
            {passwordError}
          </Typography>
        </Box>
        <Button type="submit" className="w-[350px] h-[36px] mt-0" style={{ backgroundColor: '#007AFF', color: '#fff' }} disabled={isLoading}>
          {isLoading ? "Logging in..." : "Log in with company email"}
        </Button>

        <Box display="flex" style={{ width: '100%', justifyContent: "center" }} className='mt-6'>
          <Typography variant="body2" style={{ textAlign: 'center', color: '#71717A', fontSize: '14px', maxWidth: '300px' }}>
            By clicking continue, you agree to <br /> our{' '}
            <Link to="#" className="text-gray underline">Terms of Service</Link> and{' '}
            <Link to="#" className="text-gray underline">Privacy Policy</Link>.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

const SignInPage: FC<{
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setUserEmailId: React.Dispatch<React.SetStateAction<string>>;
}> = ({ setAuthenticated, setUserEmailId }) => {
  const [email, setEmail] = useState<string>("");

  return (
    <div
      className="login-page"
      style={{
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '30px',
          left: '30px',
          zIndex: 20,
          width: '170px',
        }}
      >
        <img src={Logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>

      <div
        className="left-pane"
        style={{
          flex: '1.5',
          // backgroundImage: `url(${figmaPageImage})`,
          // backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          position: 'relative',
          overflow: 'hidden'
        }}
      
      >
         <video autoPlay loop muted playsInline className="background-video"
         style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'fill'}}>
          <source src={loginVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
       </div> 

      <div
        className="right-pane"
        style={{
          
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Box
          style={{
            width: '400px',
            maxWidth: '100%',
          }}
        >
          <CustomSignupForm
            email={email}
            setEmail={setEmail}
            setAuthenticated={setAuthenticated}
            setUserEmailId={setUserEmailId}
          />
        </Box>
      </div>

      <div className="absolute top-0 right-0 p-8">
        <Link to="/signup" style={{ color: '#007AFF' }}>
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default SignInPage;
