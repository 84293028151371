import React, { ChangeEvent, FC, useState , useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { Input } from '../../Components/ui/input';
import { Button } from "../../Components/ui/button";
import { Typography} from "@mui/material";
import { Card } from "src/Components/ui/card";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import { Image } from 'lucide-react';
import { RootState } from 'src/State/store';
import { useSelector } from 'react-redux';

import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "../../Components/ui/select";

const Workspace_settings: FC = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('Dubai Mall');
    const [Streetname, setStreetName] = useState('');
    const [Streetnumber, setStreetnumber] = useState<number | string>(''); // Can store as number or empty string initially
    const [Code, setCode] = useState<number | string>(''); // Postal Code should be an integer
    const [City, setcity] = useState('');
    const [State, setstate] = useState('');
    const [Country, setcountry] = useState('');
    const [Industry, setIndustry] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const id=localStorage.getItem("userid"); 
    const Existing_mailId = useSelector((state:RootState)=>state.authentication.userEmail);



    // const handleStreetNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   const value = e.target.value;
  
    //   // Ensure the input contains only numbers
    //   if (/^\d*$/.test(value)) {
    //     setStreetnumber(Number(value)); // Convert to a number
    //   }
    // };
  
    // const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   const value = e.target.value;
  
    //   // Allow only 6 digits in postal code
    //   if (/^\d{0,6}$/.test(value)) {
    //     setCode(Number(value)); // Convert to a number
    //   }
    // };
    const handleProfileUpdate = async () => {
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        toast.success('Profile updated!');
        setIsLoading(false);
      };
    
      const sample = async () => {
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        toast.success('Profile updated!');
        setIsLoading(false);
      };



      const UpdateWorkspaceAddress = async () => {

        try{

        const data={
        "userEmail": Existing_mailId,
        "streetName": Streetname || "",
        "streetNumber": String(Streetnumber) || "",
        "city": City || "",
        "postalCode": String(Code) || "",
        "state": State || "" ,
        "billingCountry": Country || ""

        }

        console.log("data:" + data);
       
        const response = await axios.put("http://localhost:5008/Authentication/api/UpdateWorkspaceAddress/updateWorkSpaceAddress", data);

          console.log("res:" , response)
        if (response.data.status === 'Success') {
          // toast.success("The Profile Updated successfully");
          toast.success("Company Address Updated Successfully", {
            position: "top-right",  
            autoClose: 3000,  
            hideProgressBar: true,  
            closeOnClick: true,  
          });
          setTimeout(() => {
          
          }, 3000);
        } else {
          console.error('Update failed:', response.data.Status_Description);
        }
        } catch (e) {
        console.error("Error in Workspace Address update", e);
        }

        };



    const UpdateWorkSpaceIndustry = async () => {
          
      try{
        console.log("workspaceIndustry:" + Industry);
      const data={
      "userEmail": Existing_mailId,  
      "workspaceIndustry" : Industry
      }
      console.log("data:" ,data);
      console.log ("API:" , "http://localhost:5008/Authentication/api/UpdateWorkspaceIndustry/updateWorkspaceIndustry", data);
        

      const response = await axios.put("http://localhost:5008/Authentication/api/UpdateWorkspaceIndustry/updateWorkspaceIndustry", data);

        console.log("res:" , response)
      if (response.data.status === 'Success') {
        
        toast.success("Company Industry Updated successfully", {
          position: "top-right",  // Set notification to appear in the top right corner
          autoClose: 3000,  // Notification will close after 3 seconds
          hideProgressBar: true,  // You can disable the progress bar
          closeOnClick: true,  // Close on click
        });
        setTimeout(() => {
        
        }, 3000);
      } else {
        console.error('Update failed:', response.data.Status_Description);
      }
      } catch (e) {
      console.error("Error in Company Industry update", e);
      }

        };
                  
            const handleCompanyUpdate = async () => {

            try{

            const data={
            "userAccountId": id,
            "newWorkspaceName": name

            }
           
            const response = await axios.put("http://localhost:5008/Authentication/api/UpdateCompanyName/updateWorkSpace", data);

              console.log("res:" , response)
            if (response.data.status === 'Success') {
              // toast.success("The Profile Updated successfully");
              toast.success("Company name Updated Successfully", {
                position: "top-right",  // Set notification to appear in the top right corner
                autoClose: 3000,  // Notification will close after 3 seconds
                hideProgressBar: true,  // You can disable the progress bar
                closeOnClick: true,  // Close on click
              });
              setTimeout(() => {
              
              }, 3000);
            } else {
              console.error('Update failed:', response.data.Status_Description);
            }
            } catch (e) {
            console.error("Error in Profile update", e);
            }

            };

            useEffect(() => {
            const id=localStorage.getItem("userid");
            console.log("id :"+ id);
            // handleCompanyUpdate();
            // setName(name);

            }, []); 
    

      const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
          setProfilePicture(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result as string); 
          };
          reader.readAsDataURL(file);
        }
      };

    
      const handleAccountDeletion = () => {
        toast.error('Your account has been deleted!');
      };

      const countryList = [
        { country_id: "US", country_name: "United States" },
        { country_id: "CA", country_name: "Canada" },
        { country_id: "UK", country_name: "United Kingdom" },
        { country_id: "IN", country_name: "India" },
        { country_id: "AU", country_name: "Australia" }
      ];

      const industryList = [
        { industry_id: "IT", industry_name: "IT" },
        { industry_id: "Health", industry_name: "Health" },
        { industry_id: "Finance", industry_name: "Finance" }
      ];

    return (
        <div className="flex h-screen"> 
          <div className="flex-grow ml-0 p-2 h-screen overflow-y-auto no-scrollbar"> 

          <ToastContainer />
             {/* Comapany logo */}
             <Card className="mb-8 p-6 border border-grey text-left max-w-xl ">
          <Typography component="h2" className=" mb-4" style={{fontWeight: 600 , fontSize: '14px'}}><b>Company logo</b></Typography>
          <Typography className="mb-4 mt-1" style={{fontSize:'14px', fontWeight: 400, color:'#64748B'}}>Update your team's logo to make it easier to identify</Typography>
          {/* <input 
            type="file" 
            accept="image/*" 
            onChange={handleImageUpload} 
            className="mb-4"
          />
          {imagePreview && (
            <div className="mb-4 w-32 border border-black overflow-hidden rounded"> 
              <img 
                src={imagePreview} 
                alt="Profile Preview" 
                className="w-full h-auto max-h-32 object-contain" 
              />
            </div>
          )} */}
          <div className="flex items-center gap-2 mt-4 cursor-pointer" 
            onClick={() => document.getElementById('file-upload')?.click()}>
            <div>
              {(!imagePreview && <Image className='h-[24px] w-[24px]'/>)
              || (imagePreview && <img src={imagePreview} className='h-[24px]'/>)}
              
            </div>
            <div className='flex-col'>
              <Typography style={{fontSize:'14px'}}>
                <b>Upload your profile picture</b>
              </Typography>
              <Typography style={{fontSize:'12px'}}>
              Choose a photo to upload as your profile picture.
              </Typography>
            </div>
          </div>
          <input 
            id="file-upload"
            type="file" 
            accept="image/*" 
            onChange={handleImageUpload} 
            className="hidden"
          />
        </Card>
      



            {/* Profile Information Section */}
            <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
              <Typography component="h3" className="text-xl font-bold mb-4"><b>Company Name</b></Typography>
              <Typography component="p" className="text-gray-500 mb-4">Update your team's name</Typography>
              <Input className="mb-4 mt-4" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your name" aria-label="Your name" />
              <Button onClick={handleCompanyUpdate} disabled={isLoading} className="py-1 px-3 text-sm w-[82px]">
                {isLoading ? 'Updating...' : 'Update'}
              </Button>
            </Card>
      



            {/* Update Email Section */}
            <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
              <Typography component="h3" className="text-xl font-bold mb-4"><b>Company Address</b></Typography>
              <Typography component="p" className="text-gray-500 mb-4">Update your company address</Typography>
              <Input className="mb-4 mt-4" value={Streetname} onChange={(e) => setStreetName(e.target.value)} placeholder="Street name" aria-label="Street Name" />
              <Input className="mb-4 mt-4" value={Streetnumber} onChange={(e) => setStreetnumber(e.target.value)} placeholder="Street number" aria-label="Street number" />
              {/* <Input className="mb-4 mt-4" value={Streetnumber} onChange={handleStreetNumberChange} placeholder="Street number" aria-label="Street Number" /> */}
              <Input className="mb-4 mt-4" value={City} onChange={(e) => setcity(e.target.value)} placeholder="City" aria-label="City" />
              <Input className="mb-4 mt-4" value={Code} onChange={(e) => setCode(e.target.value)} placeholder="Postal Code" aria-label="Postal Code" />
              
              {/* <Input className="mb-4 mt-4" value={Code} onChange={handlePostalCodeChange} placeholder="Postal Code" aria-label="Postal Code" /> */}
              <Input className="mb-4 mt-4" value={State} onChange={(e) => setstate(e.target.value)} placeholder="State" aria-label="State" />
<Select
      value={Country}
      onValueChange={(value) => {
        console.log("Selected Country ID:", value); // Log the selected value
        setcountry(value); // Update state with the selected value
      }}
    >
      <SelectTrigger className="text-gray-500 mt-4 text-left w-full p-2 border border-gray-300 rounded">
        <SelectValue
          className="text-gray-500 "
          placeholder="Select Country"
        />
      </SelectTrigger>
      <SelectContent>
        {countryList.map((country) => (
          <SelectItem
            className="text-gray-500"
            key={country.country_id}
            value={country.country_id}
          >
            {country.country_name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
              {emailError && <Typography className="text-red-500 mb-4">{emailError}</Typography>}
              <Button onClick={UpdateWorkspaceAddress} disabled={isLoading} className="py-1 px-3 text-sm w-[204px]">
                {isLoading ? 'Updating...' : 'Update Company address'}
              </Button>
            </Card>
      


            <Card className="mb-8 p-6 border border-grey text-left max-w-xl">
              <Typography component="h3" className="text-xl font-bold mb-4"><b>Company Industry</b></Typography>
              <Typography component="p" className="text-gray-500 mb-4">We collect this information for analytics and to provide more accurate guidance for your account.</Typography>
              
              
              <Select
      value={Industry}
      onValueChange={(value) => {
        console.log("Selected Industry:", value); // Log the selected value
        setIndustry(value); // Update state with the selected value
      }}
    >
      <SelectTrigger className="text-gray-500 mt-4 text-left w-full p-2 border border-gray-300 rounded">
        <SelectValue
          className="text-gray-500"
          placeholder="Select Industry"
        />
      </SelectTrigger>
      <SelectContent>
        {industryList.map((industry) => (
          <SelectItem
            className="text-gray-500"
            key={industry.industry_id}
            value={industry.industry_id}
          >
            {industry.industry_name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>


              <Button onClick={UpdateWorkSpaceIndustry} disabled={isLoading} className="py-1 px-3 text-sm w-[140px]">
                {isLoading ? 'Updating...' : 'Update Industry'}
              </Button>
            </Card>
      



            {/* Delete Account Section */}
            <Card className="mb-8 p-6 border border-grey text-left max-w-xl border-2 border-red-600">
            <Typography component="h3" className="mb-4"
                          style={{ 
                          fontWeight: 600, 
                          fontSize: '14px', 
                          lineHeight: '24px', 
                          color: '#020617', 
                          letterSpacing: '-1.5%' 
              
              }}> Danger zone</Typography>
              <Typography component="p" className= "text-gray-500 mb-4" style={{fontWeight: 400 , fontSize:'14px' , lineHeight: '20px'}}>
              Some actions cannot be undo. Please be careful.
              </Typography>
              <Typography component="h3" className="mb-4"
               style={{ 
                fontWeight: 600, 
                fontSize: '14px', 
                lineHeight: '24px', 
                color: '#020617', 
                letterSpacing: '-1.5%' 
    
              }}>Delete team</Typography>
              <Typography component="p" className="text-gray-500 mb-4" style={{fontWeight: 400 , fontSize:'14px' , lineHeight: '20px'}}>
                This action cannot be undone. All data associated with this team will be deleted.
              </Typography>
              <Button 
                onClick={handleAccountDeletion} 
                className="bg-red-600 text-white py-2 px-4 text-sm hover:bg-red-700 w-[167px]" >
                Delete your account
              </Button>
            </Card>
            <div className="mb-20" />
          </div>
        </div>
      );
};



export default Workspace_settings;
