// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
import './CSS/LoginPage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Typography, Box, CircularProgress } from '@mui/material';
import loginVideo from "../Assets/loginVideo.mp4";
import LoginImage from "../Assets/LoginBackground.png";
import Logo from '../Assets/Logo.svg';
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "../Components/ui/input-otp";
import crypto from 'crypto-js';
import { TIMER_SETTINGS } from '../Services/ConfigurationService';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from '../config.json';
import { useDispatch } from 'react-redux';
import { setmail,setworkspace,setWorkspaceId,setAdvUrl } from '../State/slices/AuthenticationSlice';

interface OTPProps {
    separator?: React.ReactNode;
    length: number;
    value: string;
    onChange: (value: string) => void;
}

function hashOtp(otp: string): string {
    return crypto.SHA256(otp).toString();
}

function OTP({ }: OTPProps) {
    const [PageLoad, setPageLoad] = useState<boolean>(false);

    return (
        <>
            {PageLoad && (
                <>
                    <p>Loading</p>
                    <div className="page-preloader"></div>
                </>
            )}
            {!PageLoad && (
                <Container maxWidth="xs" sx={{ padding: 2, alignItems: 'center' }}>
                    <ToastContainer />
                    <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 600, fontSize: '24px', color: '#09090B'     }}>
                            Verify your email
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom sx={{ textAlign: 'center', fontWeight:400, fontSize: '14px' ,paddingTop: '0.5rem'}} className="text-gray-500">
                            We sent you an email with your one-time password
                        </Typography>
                    </Box>
                </Container>
            )}
        </>
    );
}

export default function EmailVerificationPage() {
    const dispatch = useDispatch();
    const [timer, setTimer] = useState<number>(TIMER_SETTINGS.INITIAL_TIME);
    const [canResend, setCanResend] = useState<boolean>(false);
    const [value, setValue] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = React.useState("");
    const [Login, setLogin] = React.useState("");

    const location = useLocation();
    const navigate = useNavigate();

    const mail = location.state?.email || "";
    const login = location.state?.isLogin || "";

    const [apiUrl, setApiUrl] = useState('');

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (mail) setEmail(mail);
        if (login) setLogin(login);

        fetch('/config.json')
            .then(response => response.json())
            .then(config => {
                setApiUrl(config.API_URL);
                dispatch(setAdvUrl(config.ApiUrlAdvAcc));
            })
            .catch(error => {
                console.error('Error loading config:', error);
            });
    }, []);

    useEffect(() => {
        if (timer > 0) {
            intervalRef.current = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(intervalRef.current!);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        } else {
            setCanResend(true);
        }

        return () => {
            clearInterval(intervalRef.current!);
        };
    }, [timer]);

    const handleSubmit = async (email: string, otp: string) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/VerifyOtp`, {
                Email: email,
                Otp: otp,
            });

            if (response.status === 200 && response.data.status === "Success") {
                let path = "";
                toast.success(response.data.message, {
                    autoClose: 1000,
                    onClose: async () => {
                        const WorkspaceName = await axios.get(`${apiUrl}/GetWorkspaceNameByEmail?EmailId=${email}`);
                        if (WorkspaceName.status === 200 && WorkspaceName.data.status === "Success") {
                            dispatch(setmail(email));
                            path = WorkspaceName.data.workspaceName.workspace_name;
                            dispatch(setworkspace(path))
                            const wid = WorkspaceName.data.workspaceName.workspace_id;
                            dispatch(setWorkspaceId(wid));
                        } else {
                            path = "";
                        }
                        setIsLoading(false);
                        
                        navigate(Login === "Login" ? '/navbar/dashboard' : '/personalinfo', { state: { path, email } });
                    },
                });
            } else if (response.status === 400 && response.data.status === "Error") {
                toast.error(response.data.message, {
                    autoClose: 1000,
                    onClose: () => setIsLoading(false),
                });
            }
        } catch (error) {
            console.error('Error during API call:', error);
            toast.error("An error occurred, please try again", {
                autoClose: 1000,
                onClose: () => setIsLoading(false),
            });
            setValue('');
        }
    };

    const handleResendOTP = async (email: string) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/SendOtp/SendOtp`, {
                Email: email,
            });

            if (response.status === 200 && response.data.status === "Success") {
                toast.success(response.data.message, {
                    autoClose: 1000,
                    onClose: () => {
                        setIsLoading(false);
                        setTimer(TIMER_SETTINGS.INITIAL_TIME);
                        setCanResend(false);
                    },
                });
            } else {
                toast.error("Failed to resend OTP. Please try again.", {
                    autoClose: 1000,
                    onClose: () => setIsLoading(false),
                });
            }
        } catch (error) {
            console.error('Error during API call:', error);
            toast.error("An error occurred while resending OTP, please try again later.");
        }
    };

    return (
        <div className="email-verification-page" style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            {isLoading && (
                <div className="loading-overlay">
                    <CircularProgress color="primary" />
                </div>
            )}
                  <div
        style={{
          position: 'fixed',
          top: '30px',
          left: '30px',
          zIndex: 20,
          
        }}
      >
        <img src={Logo} alt="Logo" style={{ width: '170px', height: 'auto' }} />
      </div>
      <div
        className="left-pane"
        style={{
          flex: '1.5',
          // backgroundImage: `url(${figmaPageImage})`,
          // backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          position: 'relative',
          overflow: 'hidden'
        }}
      
      >
         <video autoPlay loop muted playsInline className="background-video"
         style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'fill'}}>
          <source src={loginVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
       </div>
            
            {/* Right pane without any structural or alignment changes */}
            <div className="right-pane" style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="LoginMatCard" style={{ width: '90%', maxWidth: '400px', marginTop: '12px' }}>
                    <OTP length={0} value={''} onChange={() => { }} />
                    <InputOTP
                        maxLength={6}
                        value={value}
                        onChange={(value) => {
                            setValue(value);
                            if (value.length === 6) {
                                const hashedOtp = hashOtp(value);
                                handleSubmit(email, hashedOtp);
                            }
                        }}
                    >
                        <InputOTPGroup>
                            <InputOTPSlot index={0} className="border border-[#E2E8F0]"/>
                            <InputOTPSlot index={1} className="border border-[#E2E8F0]"/>
                            <InputOTPSlot index={2} className="border border-[#E2E8F0]"/>
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                            <InputOTPSlot index={3} className="border border-[#E2E8F0]"/>
                            <InputOTPSlot index={4} className="border border-[#E2E8F0]"/>
                            <InputOTPSlot index={5} className="border border-[#E2E8F0]"/>
                        </InputOTPGroup>
                    </InputOTP>

                    <Typography variant="subtitle2" sx={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: '#000000', fontWeight: 400 }}>
                        Enter your one-time password
                    </Typography>

                    <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', color: '#AEAEAE', cursor: 'pointer', marginTop: '30px' }}>
                        {canResend ? (
                            <Button
                                style={{ backgroundColor: 'black', color: 'white', textTransform: 'capitalize' }}
                                onClick={() => handleResendOTP(email)}
                            >
                                Resend OTP
                            </Button>
                        ) : (
                            `Resend OTP in ${timer} seconds`
                        )}
                    </Typography>
                </div>
            </div>
        </div>
    );
}
