import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AdvAccState{
    createBreadCrumb:boolean,
    addWorkspaceFromDropdown: boolean,
    closeAddWorkspaceDialog: boolean,
}

const initialState:AdvAccState={
    createBreadCrumb:false,
    addWorkspaceFromDropdown:false,
    closeAddWorkspaceDialog:false
}

const AdvertiserAccountSlice = createSlice({
    name: 'AdvertiserAccount',
    initialState:initialState,
    reducers:{
        setCreateBreadCrumb:(state,action: PayloadAction<boolean>)=>{

            state.createBreadCrumb=action.payload;
        },

        setAddWorkspaceFromDropdown:(state,action: PayloadAction<boolean>)=>{
            state.addWorkspaceFromDropdown=action.payload;
        },
        setCloseAddWorkspaceDialog:(state,action: PayloadAction<boolean>)=>{
            state.closeAddWorkspaceDialog=action.payload;
        }

        
    }
})

export const {setCreateBreadCrumb,setAddWorkspaceFromDropdown,setCloseAddWorkspaceDialog} = AdvertiserAccountSlice.actions;

export default AdvertiserAccountSlice.reducer;