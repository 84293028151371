import { useState } from "react";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import { Card } from "../../Components/ui/card"; // Assuming you're using Shadcn's Card component

const ChannelList = () => {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);

  const channels = ["SMS", "WhatsApp", "Push Notifications"];

  return (
    <div className="flex gap-4 p-2">
      {channels.map((ch, idx) => (
        <Card
          key={idx}
          className={`w-[250px] p-6 pt-8 pb-8 relative cursor-pointer border ${
            selectedChannel === ch ? "border-gray-300" : "border-gray-300"
          }`}
          onClick={() => setSelectedChannel(ch)}
        >
          {/* Icon on the top right */}

          {/* Content aligned to the left */}
          <div className="flex w-full">
            <div>
              <h3 className="font-semibold text-left flex w-full mb-2">{ch}</h3>
            </div>
            <div className="flex w-full justify-end absolute right-7 mt-1">
              {selectedChannel === ch ? (
                <CheckIcon className="text-blue-500 w-4 h-4 rounded-full border border-blue-500" />
              ) : (
                <Cross1Icon className="text-gray-200 w-4 h-4 rounded-full border border-gray-200" />
              )}
            </div>
          </div>

          <p className="mt-2 text-sm text-gray-500 text-left w-[calc(100%-20px)]">
            {ch === "SMS" &&
              "Direct offers and immediate delivery driving high conversions"}
            {ch === "WhatsApp" &&
              "Reach 2 billion users worldwide with high read and engagement rates"}
            {ch === "Push Notifications" &&
              "Instantly engage your mobile app users with timely and relevant notifications"}
          </p>
        </Card>
      ))}
    </div>
  );
};

export default ChannelList;
